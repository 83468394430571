import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  CompanyData: null,
  CompanyContracts: null,
  TotalCount: 0,
  message: null,
  papulateCompanies: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const fetchCompanyData = createAsyncThunk(
  "Company/GetAll",
  async ({ page, pageSize, sortBy }) => {
    const response = await axiosInstance.get(
      `Company/GetAll?page=${page}&limit=${pageSize}&sort=${sortBy}`
    );
    return response?.data;
  }
);
export const fetchPapulateCompanies = createAsyncThunk(
  "Contact/GetContactPopulate",
  async () => {
    const response = await axiosInstance.get(`Contact/GetContactPopulate`);
    return response?.data;
  }
);
export const fetchCompanyContracts = createAsyncThunk(
  "Company/GetCompanyContacts",
  async (companyId) => {
    const response = await axiosInstance.get(
      `Company/GetCompanyContacts?companyId=${companyId}`
    );
    return response?.data;
  }
);
const CompanySlice = createSlice({
  name: "CompanySlice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchCompanyData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCompanyData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.CompanyData = action.payload.data.companiesdata;
        state.TotalCount = action.payload.data.TotalCount;
      })
      .addCase(fetchCompanyData.rejected, (state, action) => {
        state.CompanyData = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchPapulateCompanies.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPapulateCompanies.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.papulateCompanies = action.payload.data;
        state.TotalCount = action.payload.data.TotalCount;
      })
      .addCase(fetchPapulateCompanies.rejected, (state, action) => {
        state.CompanyData = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchCompanyContracts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCompanyContracts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.CompanyContracts = action.payload.data;
      })
      .addCase(fetchCompanyContracts.rejected, (state, action) => {
        state.CompanyContracts = null;
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export default CompanySlice.reducer;
