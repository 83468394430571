import { React, useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles, styled } from "@mui/styles";
import { useFormik } from "formik";
import { ThreeDots } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import MuiPhoneNumber from "material-ui-phone-number";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Autocomplete,
  Avatar,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { City, State } from "country-state-city";
import * as Yup from "yup";
import { SnackBarContext } from "../../context/SnackBarContext";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  CreateContactApi,
  UpdateContactApi,
} from "../../Apis/Contacts/CreateContactsApi";
import { fetchNotes } from "../../redux/slice/ContactsSlice/ContactSlice";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  fetchCompanyData,
  fetchPapulateCompanies,
} from "../../redux/slice/CompanySlice/CompanySlice";
import CompaniesFormDialog from "../Companies/CompaniesFormDialog/CompaniesFormDialog";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, RichUtils, getDefaultKeyBinding } from "draft-js";
import { convertToHTML } from "draft-convert";
import AddNoteDialog from "../AddNoteDialog/AddNoteDialog";
import Loader from "../Loader/Loader";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AppConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import { DeleteNotesApi } from "../../Apis/Notes/NotesApi";
import moment from "moment";

function ContactsForm({
  parent,
  onClose,
  screen,
  contactDialogFor,
  setFieldValue,
}) {
  console.log(contactDialogFor, parent);
  const classes = useStyles();
  const { papulateCompanies } = useSelector((state) => state?.Companies);
  const { Notes, status } = useSelector((state) => state?.Contacts);

  const [loading, setLoading] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [states, setStates] = useState([]);
  const [state, setState] = useState(null);
  const [cities, setCities] = useState([]);
  const [showCompanyDialog, setShowCompanyDialog] = useState(false);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [note, setNote] = useState("");
  const contactData = useLocation();
  const [image, setImage] = useState(null);
  const [update] = useState(contactData.state ? true : false);
  const [showAddNotesDialog, setShowAddNotesDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const deleteNote = async () => {
    setLoading(true);
    setShowConfirmation(false);

    const response = await DeleteNotesApi(selectedRow?.notesId);

    response?.snackBarData?.message && setsnackBarData(response?.snackBarData);
    if (response?.success) {
      dispatch(
        fetchNotes({ type: "Contact", id: contactData?.state?.contactId })
      );
      setSelectedRow(null);
    }
    setLoading(false);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchPapulateCompanies());
    update &&
      dispatch(
        fetchNotes({ type: "Contact", id: contactData?.state?.contactId })
      );
  }, [dispatch, contactData.state]);
  console.log("contactData?.state", contactData?.state);
  useEffect(() => {
    if (contactData?.state && parent !== "dialog") {
      formik.setFieldValue("name", contactData.state?.display_name);
      formik.setFieldValue("firstName", contactData.state?.first_name);
      formik.setFieldValue("lastName", contactData.state?.last_name);
      formik.setFieldValue("email", contactData.state?.email);
      formik.setFieldValue("phone", contactData.state?.phone);
      formik.setFieldValue("state", contactData.state?.state);
      formik.setFieldValue("city", contactData.state?.city);
      formik.setFieldValue("address", contactData.state?.address);
      formik.setFieldValue("zipcode", contactData.state?.zipcode);
      formik.setFieldValue("type", contactData.state?.type);
      formik.setFieldValue("active", contactData.state?.active);
      formik.setFieldValue("companyId", contactData.state?.companyId);

      formik.setFieldValue("imageName", contactData.state?.imageName);
      setImage({
        url: contactData.state?.image,
      });
    }
  }, [contactData]);
  const validationSchema = Yup.object({
    // phone: Yup.string().required("Field required!"),
    email: Yup.string()
      .email("Enter a valid Email")
      .required("Field required!"),

    address: Yup.string().required("Field required!"),
    city: cities?.length
      ? Yup.string().required("Field required!")
      : Yup.string(),
    state: Yup.string().required("Field required!"),
    name: Yup.string().required("Field required!"),
    firstName: Yup.string().required("Field required!"),
    lastName: Yup.string(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      type:
        parent === "dialog" && screen === "opportunity"
          ? contactDialogFor === "contact"
            ? "General"
            : "GC"
          : parent === "dialog" && screen === "create_job"
          ? "GC"
          : "",
      name: "",
      address: "",
      zipcode: "",
      email: "",
      state: "",
      city: "",
      phone: "",
      lastName: "",
      firstName: "",
      active: true,
      companyId: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      if (contactData.state) {
        const formdata = new FormData();
        formdata.append("first_name", values.firstName);
        formdata.append("last_name", values.lastName);
        formdata.append("display_name", values.name);
        formdata.append("phone", values.phone);
        formdata.append("email", values.email);
        formdata.append("address", values.address);
        formdata.append("city", values.city);
        formdata.append("state", values.state);
        formdata.append("zipcode", values.zipcode);
        formdata.append("type", values.type);
        formdata.append("active", values.active);
        values.companyId && formdata.append("companyId", values.companyId);
        if (note) {
          formdata.append("note", note);
        }

        image?.file
          ? formdata.append("image", image?.file)
          : image?.url
          ? null
          : formdata.append("image", "");
        const response = await UpdateContactApi(
          contactData.state?.contactId,
          formdata
        );
        response?.snackBarData?.message &&
          setsnackBarData(response?.snackBarData);

        if (response?.success) {
          // dispatch(fetchContactsData());
          resetForm();
          setLoading(false);
          if (parent === "dialog") {
            console.log(parent);

            setFieldValue("contactId", response?.data?.contactId);
            onClose(true);
          } else {
            navigate(-1);
          }
        } else {
          setLoading(false);
        }
      } else {
        const formdata = new FormData();
        formdata.append("first_name", values.firstName);
        formdata.append("last_name", values.lastName);
        formdata.append("display_name", values.name);
        formdata.append("phone", values.phone);
        formdata.append("email", values.email);
        formdata.append("address", values.address);
        formdata.append("city", values.city);
        formdata.append("state", values.state);
        formdata.append("zipcode", values.zipcode);
        formdata.append("type", values.type);
        formdata.append("active", values?.active);
        values.companyId && formdata.append("companyId", values.companyId);
        if (note) {
          formdata.append("note", note);
        }
        image?.file && formdata.append("image", image ? image?.file : null);

        const response = await CreateContactApi(formdata);

        response?.snackBarData?.message &&
          setsnackBarData(response?.snackBarData);
        if (response?.success) {
          resetForm();
          setLoading(false);
          console.log(parent);
          if (parent === "dialog") {
            console.log(parent);

            setFieldValue("contactId", response?.data?.contactId);
            onClose(true);
          } else {
            navigate(-1);
          }
        } else {
          setLoading(false);
        }
      }
    },
  });
  const handlePhoneChange = (value) => {
    formik.setFieldValue("phone", value);
  };
  console.log(formik.values);

  useEffect(() => {
    let value = State.getStatesOfCountry("US");
    value = value?.map((data) => {
      let obj = {
        label: data?.name,
        value: data?.isoCode,
      };
      return obj;
    });
    setStates(value);
  }, []);
  useEffect(() => {
    if (state) {
      let value = City.getCitiesOfState("US", state);
      value = value?.map((data) => {
        return data?.name;
      });
      if (state === "FL") {
        value.push("Parrish", "Myakka");
      }
      setCities(value);
    } else {
      setCities([]);
    }
  }, [state]);
  const handleLogo = (e) => {
    setImage({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  };
  const styleToHTML = (style) => {
    if (style.startsWith("color-")) {
      const color = style.replace("color-", "");
      return <span style={{ color: color }} />;
    }
  };

  useEffect(() => {
    const html = convertToHTML({
      styleToHTML,
    })(editorState.getCurrentContent());

    if (html !== "<p></p>") {
      setNote(html);
    }
  }, [editorState]);
  const onChange = async (value) => {
    setEditorState(value);
  };

  const handleKeyCommand = (command, editorState) => {
    // Intercept Enter key press
    if (command === "split-block") {
      // Create a new EditorState with a soft line break instead of a new paragraph
      const newState = RichUtils.insertSoftNewline(editorState);
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const mapKeyToEditorCommand = (e) => {
    // Handle Enter key press with shift
    if (e.keyCode === 13 /* 'Enter' key */ && e.shiftKey) {
      return "split-block";
    }
    return getDefaultKeyBinding(e);
  };
  return (
    <Box
      sx={{
        backgroundColor: parent == "dialog" ? "transparent" : "#fff",
        borderRadius: parent == "dialog" ? "0px" : "12px",
        boxShadow: parent == "dialog" ? "none" : "0 3px 10px rgb(0 0 0 / 0.2)",
        width: "100%",
        height: parent == "dialog" ? "auto" : "calc(100vh - 100px)",
        overflowY: parent == "dialog" ? "hidden" : "scroll",
      }}
    >
      <form
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
        className={classes.formContainer}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {parent !== "dialog" && (
              <>
                <Typography className={classes?.fieldsHeading}>
                  Contact Information
                </Typography>
                <Divider sx={{ mb: 2 }} />
              </>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // backgroundColor: "#f0f9fb",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      width: "100px",
                      height: "100px",
                      // height: "100%",
                      position: "relative",
                    }}
                  >
                    {image?.url ? (
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          maxWidth: "100px",
                          // borderRadius: "5px",
                          // border: "1px solid rgb(214, 214, 214)",
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer !important",
                          justifyContent: "center",
                          position: "relative",
                          borderRadius: "50%",
                          // padding: "10px",
                        }}
                      >
                        <img
                          src={image?.url}
                          alt="Image"
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                          }}
                        />
                        <CancelIcon
                          color="error"
                          onClick={() => {
                            setImage(null);
                          }}
                          sx={{
                            position: "absolute",
                            top: "-2px",
                            right: "-2px",
                            fontSize: "16px",
                            zIndex: 99,
                          }}
                        />
                      </Box>
                    ) : (
                      <Avatar
                        sx={{
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                        }}
                        src={"./"}
                      />
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      name="product_images"
                      style={{
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                        top: "0px",
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      onChange={handleLogo}
                    />
                  </Box>

                  <Box sx={{ position: "relative", mt: 2 }}>
                    <FormControlLabel
                      control={
                        <Android12Switch
                          checked={formik.values.active}
                          onChange={() =>
                            formik.setFieldValue(
                              "active",
                              !formik.values.active
                            )
                          }
                        />
                      }
                      label={formik.values.active ? "Active" : "Inactive"}
                    />
                  </Box>
                </Box>
              </Grid>{" "}
              <Grid item xs={12} md={10}>
                <Grid container spacing={2}>
                  <Grid item xs={11} md={6}>
                    <TextField
                      fullWidth
                      select
                      size="small"
                      label={"Company"}
                      name="companyId"
                      value={formik?.values?.companyId}
                      // onChange={formik.handleChange}
                      onChange={(event) => {
                        const selectedCompanyId = event.target.value;
                        formik.handleChange(event);

                        const selectedCompany = papulateCompanies?.find(
                          (company) => company.companyId === selectedCompanyId
                        );
                        console.log("Company", selectedCompany);
                        if (selectedCompany) {
                          formik.setFieldValue(
                            "address",
                            selectedCompany?.address || ""
                          );
                          formik.setFieldValue(
                            "state",
                            selectedCompany?.state || ""
                          );
                          formik.setFieldValue(
                            "city",
                            selectedCompany?.city || ""
                          );
                          formik.setFieldValue(
                            "zipcode",
                            selectedCompany?.zipCode || ""
                          );
                        }
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors?.companyId && formik.touched?.companyId
                      }
                      helperText={
                        formik.errors?.companyId && formik.touched?.companyId
                          ? formik.errors?.companyId
                          : ""
                      }
                    >
                      {papulateCompanies?.map((company, idx) => (
                        <MenuItem key={idx} value={company?.companyId}>
                          {company?.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={1} md={6}>
                    <Tooltip title="Add New Company">
                      <IconButton
                        onClick={() => setShowCompanyDialog(true)}
                        color="primary"
                        aria-label="delete"
                      >
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      name="firstName"
                      type="text"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      label="First Name"
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors?.firstName && formik.touched?.firstName
                      }
                      helperText={
                        formik.errors?.firstName && formik.touched?.firstName
                          ? formik.errors?.firstName
                          : ""
                      }
                    ></TextField>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      name="lastName"
                      type="text"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      label="Last Name"
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors?.lastName && formik.touched?.lastName
                      }
                      helperText={
                        formik.errors?.lastName && formik.touched?.lastName
                          ? formik.errors?.lastName
                          : ""
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="name"
                      type="text"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      label="Display Name"
                      onBlur={formik.handleBlur}
                      error={formik.errors?.name && formik.touched?.name}
                      helperText={
                        formik.errors?.name && formik.touched?.name
                          ? formik.errors?.name
                          : ""
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      select
                      name="type"
                      variant="outlined"
                      fullWidth
                      size="small"
                      disabled={parent === "dialog"}
                      value={formik.values.type}
                      onChange={formik.handleChange}
                      label="Type"
                      onBlur={formik.handleBlur}
                      error={formik.touched.type && Boolean(formik.errors.type)}
                      helperText={formik.touched.type && formik.errors.type}
                    >
                      <MenuItem value={"General"}>Contact</MenuItem>
                      <MenuItem value={"GC"}>General Contractor</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MuiPhoneNumber
                      defaultCountry={"us"}
                      disableAreaCodes={true}
                      regions={["america"]}
                      onlyCountries={["us"]}
                      value={formik.values?.phone}
                      onChange={handlePhoneChange}
                      variant="outlined"
                      label="Phone"
                      name="number"
                      size="small"
                      fullWidth
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                      helperText={formik.touched.phone && formik.errors.phone}
                      // error={phoneError}
                      // helperText={phoneError && "Number must contain 11 digits"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      type="email"
                      name="email"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      label="Email"
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>

                  <Grid item md={12}>
                    <TextField
                      type="text"
                      name="address"
                      variant="outlined"
                      fullWidth
                      size="small"
                      multiline
                      rows={3}
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      label="Street Address"
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.address && Boolean(formik.errors.address)
                      }
                      helperText={
                        formik.touched.address && formik.errors.address
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched.state && Boolean(formik.errors.state)
                      }
                    >
                      <Autocomplete
                        id="combo-box-demo"
                        size="small"
                        style={{ width: "100%" }}
                        options={states}
                        value={formik.values?.state}
                        // getOptionLabel={(option) => option.label}
                        onChange={(e, v) => {
                          setState(v?.value);
                          formik.setFieldValue("state", v?.label);
                          formik.setFieldValue("city", "");
                        }}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField {...params} label="State" />
                        )}
                      />
                      <FormHelperText>
                        {formik.touched.state && formik.errors.state}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl
                      fullWidth
                      error={formik.touched.city && Boolean(formik.errors.city)}
                    >
                      <Autocomplete
                        disablePortal
                        size="small"
                        style={{ width: "100%" }}
                        value={formik.values?.city}
                        id="combo-box-demo"
                        options={cities}
                        onChange={(e, v) => {
                          formik.setFieldValue("city", v);
                        }}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField fullWidth {...params} label="City" />
                        )}
                      />
                      <FormHelperText>
                        {formik.touched.city && formik.errors.city}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      type="number"
                      name="zipcode"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={formik.values.zipcode}
                      onChange={formik.handleChange}
                      label="Zip Code"
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.zipcode && Boolean(formik.errors.zipcode)
                      }
                      helperText={
                        formik.touched.zipcode && formik.errors.zipcode
                      }
                    />
                  </Grid>
                  {!update ? (
                    <Grid item xs={12}>
                      <Typography className={classes?.fieldsHeading}>
                        Notes
                      </Typography>
                      <Divider sx={{ mb: 2 }} />
                      <FormControl fullWidth>
                        <Box
                          sx={{
                            maxHeight: "500px",
                            overflow: "auto",
                            border:
                              Boolean(formik.errors.body) &&
                              Boolean(formik.touched.body)
                                ? "1px solid #d32f2f"
                                : "1px solid #bdbdbd",
                            padding: "8px",
                          }}
                        >
                          <Editor
                            placeholder="Start Typing your note here"
                            editorState={editorState}
                            handleKeyCommand={handleKeyCommand}
                            keyBindingFn={mapKeyToEditorCommand}
                            onEditorStateChange={(value) => {
                              onChange(value);
                            }}
                          />
                        </Box>
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {Boolean(formik.errors.body) &&
                          Boolean(formik.touched.body)
                            ? "Field Required!"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box
          sx={{
            marginTop: "30px",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <ThreeDots
            height="40"
            width="40"
            radius="9"
            color="#f79c35"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={loading}
          />

          {parent == "screen" && (
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </Button>
          )}

          <Button
            type="submit"
            variant="contained"
            disabled={loading}
            color="primary"
          >
            Save
          </Button>
        </Box>
        {update && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}></Grid>
            <Grid item xs={12} md={10}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes?.fieldsHeading}>
                    Notes
                  </Typography>
                  <Divider sx={{ mb: 2 }} />

                  <TableContainer
                    component={Box}
                    sx={{ marginTop: "5px", maxHeight: "300px" }}
                  >
                    <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                      <TableHead className="table-head">
                        <TableRow>
                          <TableCell width={"5%"}>Sr.</TableCell>
                          <TableCell>Notes</TableCell>
                          <TableCell width={"10%"}>Created By</TableCell>
                          <TableCell width={"10%"}>Created At</TableCell>

                          <TableCell width={"10%"}>
                            {" "}
                            <Button
                              variant="contained"
                              sx={{ float: "right" }}
                              size="small"
                              onClick={() => {
                                setShowAddNotesDialog(true);
                              }}
                            >
                              Add New
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Notes?.data?.map((item, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell>{index + 1}</TableCell>

                            <TableCell
                              dangerouslySetInnerHTML={{ __html: item?.note }}
                            ></TableCell>
                            <TableCell>
                              {item?.createdByUser?.name || "N/A"}
                            </TableCell>
                            <TableCell>
                              {moment(
                                item?.createdAt,
                                "YYYY-MM-DDTHH:mm:ss a"
                              )?.format("MM/DD/YYYY") || "N/A"}
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{
                                  display: "flex",
                                  float: "right",
                                  mr: 2,
                                }}
                              >
                                <Tooltip title="Edit">
                                  <IconButton
                                    sx={{ padding: "5px" }}
                                    color="info"
                                    onClick={() => {
                                      setSelectedRow(item);
                                      setShowAddNotesDialog(true);
                                    }}
                                  >
                                    <EditIcon sx={{ fontSize: "1rem" }} />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                  <IconButton
                                    sx={{ padding: "5px" }}
                                    color="error"
                                    onClick={() => {
                                      setSelectedRow(item);
                                      setShowConfirmation(true);
                                    }}
                                  >
                                    <DeleteOutlineIcon
                                      sx={{ fontSize: "1rem" }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </form>
      <CompaniesFormDialog
        open={showCompanyDialog}
        onClose={(success) => {
          if (success) {
            dispatch(fetchCompanyData());
          }
          setShowCompanyDialog(false);
        }}
        screen="contacts"
        setFieldValue={setFieldValue}
      />
      {showAddNotesDialog && (
        <AddNoteDialog
          open={showAddNotesDialog}
          onClose={(success) => {
            console.log(success);
            if (success)
              dispatch(
                fetchNotes({
                  type: "Contact",
                  id: contactData?.state?.contactId,
                })
              );
            setShowAddNotesDialog(false);
            setSelectedRow(null);
          }}
          setNote={setNote}
          referenceId={contactData?.state?.contactId}
          referenceType={"Contact"}
          updateData={selectedRow}
        />
      )}
      {status === "loading" && <Loader />}
      {showConfirmation && (
        <AppConfirmationDialog
          open={showConfirmation}
          onDeny={() => {
            setSelectedRow(null);
            setShowConfirmation(false);
          }}
          onConfirm={() => {
            deleteNote();
          }}
          iconContainerColor="#fbeaea"
          icon={
            <DeleteOutlineIcon
              fontSize="small"
              sx={{ color: (theme) => theme?.palette?.error?.main }}
            />
          }
          dialogTitle="Delete Note"
          title={`Are you sure, you want to delete selected note?`}
          successText="Yes"
          successBtnColor="error"
          cancelText="No"
        />
      )}
    </Box>
  );
}

export default ContactsForm;
ContactsForm.propTypes = {
  parent: PropTypes.string,
  onClose: PropTypes.func,
  screen: PropTypes.string,
  contactDialogFor: PropTypes.string,
  setFieldValue: PropTypes.any,
};

const useStyles = makeStyles(() => ({
  formContainer: {
    padding: "1.5rem",
  },
  fieldsHeading: {
    fontSize: "1.125rem !important",
    color: "rgb(38 50 56 /1) !important",
    lineHeight: "1.625 !important",
    letterSpacing: "0 !important",
    fontWeight: "600 !important",
    marginBottom: "10px !important",
  },
}));

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&::before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette?.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&::after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette?.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));
