import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { fetchOpportunitySalesSummaryData } from "../../../redux/slice/OpportunitySettingsSlice/OpportunitySettingsSlice";
import {
  Badge,
  Box,
  Divider,
  Grid,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import Loader from "../../../components/Loader/Loader";
import DateFilter from "../../../components/common/DateFilter/DateFilter";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import { makeStyles } from "@mui/styles";
import CustomTabPanel from "../../../components/common/CustomTabPanel/CustomTabPanel";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useNavigate } from "react-router-dom";

function SalesSummary() {
  const classes = useStyles();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [showDateRangeDialog, setShowDateRangeDialog] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { OpportunitySalesSummaryData, status } = useSelector(
    (state) => state?.OpportunitySettings
  );

  useEffect(() => {
    callOpportunitySummaryApi();
  }, []);

  const callOpportunitySummaryApi = () => {
    dispatch(fetchOpportunitySalesSummaryData({ startDate, endDate }));
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  console.log("dates", startDate, endDate);
  console.log("OpportunitySalesSummaryData", OpportunitySalesSummaryData);
  return (
    <>
      <Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <InfoOutlinedIcon color="info" sx={{ fontSize: "15px" }} />
            <Typography
              sx={{ fontSize: "18px", fontWeight: "bold", color: "#888" }}
            >
              {startDate && endDate ? (
                <>
                  Results from {moment(startDate).format("Do MMM")} to{" "}
                  {moment(endDate).format("Do MMM")}
                </>
              ) : (
                "All "
              )}
            </Typography>
          </Box>

          <Tooltip title="Change Date Range">
            <Badge
              badgeContent={
                startDate && endDate
                  ? `${moment(endDate).diff(startDate, "days") + 1} Days`
                  : "All"
              }
              color="primary"
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
            >
              <Box
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "5px",
                }}
                onClick={() => {
                  setShowDateRangeDialog(true);
                }}
              >
                <IconButton color="primary" sx={{ padding: 0 }}>
                  <Box
                    sx={{
                      backgroundColor: "white",
                      padding: "8px",
                      borderRadius: "50%",
                      boxShadow: "1px 1px 3px 1px rgba(0, 0, 0, 0.3)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DateRangeOutlinedIcon />
                  </Box>
                </IconButton>
              </Box>
            </Badge>
          </Tooltip>
        </Box>

        <Box>
          <Grid mt={4} container spacing={3}>
            <Grid item xs={12} sm={6} xl={3}>
              <Box
                sx={{
                  backgroundColor: "#fbfbfb",
                  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                  borderRadius: "0.75rem",
                  "&:hover": {
                    cursor: "pointer",
                    boxShadow: "0 3px 10px rgb(0 0 0 / 0.6)",
                  },
                }}
              >
                <Box className={classes.statsContainer}>
                  <Box className={`${classes.infoContainer}`}>
                    <Typography
                      sx={{
                        color: "#7f90a1",
                        lineHeight: 1.5,
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      Total Count
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{
                        letterSpacing: 0,
                        lineHeight: 1.375,
                        fontWeight: 600,
                        fontSize: "1.5rem",
                      }}
                    >
                      {OpportunitySalesSummaryData?.totalCount}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} xl={3}>
              <Box
                sx={{
                  backgroundColor: "#fbfbfb",
                  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                  borderRadius: "0.75rem",
                  "&:hover": {
                    cursor: "pointer",
                    boxShadow: "0 3px 10px rgb(0 0 0 / 0.6)",
                  },
                }}
              >
                <Box className={classes.statsContainer}>
                  <Box className={`${classes.infoContainer}`}>
                    <Typography
                      sx={{
                        color: "#7f90a1",
                        lineHeight: 1.5,
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      Total Revenue
                    </Typography>
                    <Tooltip
                      title={OpportunitySalesSummaryData?.totalRevenue?.toLocaleString()}
                    >
                      <Typography
                        variant="h4"
                        sx={{
                          letterSpacing: 0,
                          lineHeight: 1.375,
                          fontWeight: 600,
                          fontSize: "1.5rem",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        $
                        {OpportunitySalesSummaryData?.totalRevenue?.toLocaleString()}
                      </Typography>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} xl={3}>
              <Box
                sx={{
                  backgroundColor: "#fbfbfb",
                  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                  borderRadius: "0.75rem",
                  "&:hover": {
                    cursor: "pointer",
                    boxShadow: "0 3px 10px rgb(0 0 0 / 0.6)",
                  },
                }}
              >
                <Box className={classes.statsContainer}>
                  <Box className={`${classes.infoContainer}`}>
                    <Typography
                      sx={{
                        color: "#7f90a1",
                        lineHeight: 1.5,
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      Sold Count
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{
                        letterSpacing: 0,
                        lineHeight: 1.375,
                        fontWeight: 600,
                        fontSize: "1.5rem",
                      }}
                    >
                      {OpportunitySalesSummaryData?.soldCount}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} xl={3}>
              <Box
                sx={{
                  backgroundColor: "#fbfbfb",
                  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                  borderRadius: "0.75rem",
                  "&:hover": {
                    cursor: "pointer",
                    boxShadow: "0 3px 10px rgb(0 0 0 / 0.6)",
                  },
                }}
              >
                <Box className={classes.statsContainer}>
                  <Box className={`${classes.infoContainer}`}>
                    <Typography
                      sx={{
                        color: "#7f90a1",
                        lineHeight: 1.5,
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      Lost Count
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{
                        letterSpacing: 0,
                        lineHeight: 1.375,
                        fontWeight: 600,
                        fontSize: "1.5rem",
                      }}
                    >
                      {OpportunitySalesSummaryData?.lostCount}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} xl={3}>
              <Box
                sx={{
                  backgroundColor: "#fbfbfb",
                  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                  borderRadius: "0.75rem",
                  "&:hover": {
                    cursor: "pointer",
                    boxShadow: "0 3px 10px rgb(0 0 0 / 0.6)",
                  },
                }}
              >
                <Box className={classes.statsContainer}>
                  <Box className={`${classes.infoContainer}`}>
                    <Typography
                      sx={{
                        color: "#7f90a1",
                        lineHeight: 1.5,
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      No Opportunity Count
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{
                        letterSpacing: 0,
                        lineHeight: 1.375,
                        fontWeight: 600,
                        fontSize: "1.5rem",
                      }}
                    >
                      {OpportunitySalesSummaryData?.noOpportunityCount}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} xl={3}>
              <Box
                sx={{
                  backgroundColor: "#fbfbfb",
                  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                  borderRadius: "0.75rem",
                  "&:hover": {
                    cursor: "pointer",
                    boxShadow: "0 3px 10px rgb(0 0 0 / 0.6)",
                  },
                }}
              >
                <Box className={classes.statsContainer}>
                  <Box className={`${classes.infoContainer}`}>
                    <Typography
                      sx={{
                        color: "#7f90a1",
                        lineHeight: 1.5,
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      Conversion Rate Sold
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{
                        letterSpacing: 0,
                        lineHeight: 1.375,
                        fontWeight: 600,
                        fontSize: "1.5rem",
                      }}
                    >
                      {OpportunitySalesSummaryData?.conversionRateSold}%
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} xl={3}>
              <Box
                sx={{
                  backgroundColor: "#fbfbfb",
                  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                  borderRadius: "0.75rem",
                  "&:hover": {
                    cursor: "pointer",
                    boxShadow: "0 3px 10px rgb(0 0 0 / 0.6)",
                  },
                }}
              >
                <Box className={classes.statsContainer}>
                  <Box className={`${classes.infoContainer}`}>
                    <Typography
                      sx={{
                        color: "#7f90a1",
                        lineHeight: 1.5,
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      Conversion Rate Lost
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{
                        letterSpacing: 0,
                        lineHeight: 1.375,
                        fontWeight: 600,
                        fontSize: "1.5rem",
                      }}
                    >
                      {OpportunitySalesSummaryData?.conversionRateLost}%
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            marginTop: "30px",
            backgroundColor: "#fff",
            borderRadius: "12px",
            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
            padding: "10px",
          }}
        >
          {/* Tabs */}
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab
              sx={{ textTransform: "capitalize" }}
              label="Sold Opportunities"
              {...a11yProps(0)}
            />
            <Tab
              sx={{ textTransform: "capitalize" }}
              label="Lost Opportunities"
              {...a11yProps(1)}
            />
          </Tabs>
          <Divider sx={{ mb: 2 }} />
          <CustomTabPanel value={tabValue} index={0}>
            <Box sx={{ marginTop: "20px" }}>
              <TableContainer
                component={Box}
                sx={{
                  marginTop: "10px",
                  minHeight: "250px",
                  maxHeight: "380px",
                }}
              >
                <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                  <TableHead className="table-head">
                    <TableRow>
                      <TableCell>Sr. </TableCell>
                      <TableCell>Job# </TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Sold Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {OpportunitySalesSummaryData?.soldOpportunities?.map(
                      (opportunity, index) => {
                        return (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{opportunity?.jobNo || "N/A"}</TableCell>
                            <TableCell>{opportunity?.title}</TableCell>
                            <TableCell>
                              ${opportunity?.opportunityValue?.toLocaleString()}
                            </TableCell>
                            <TableCell>
                              {opportunity?.company?.name ? (
                                <Box
                                  sx={{
                                    cursor: "pointer",
                                    color: "#551a8b",
                                    "&:hover": {
                                      textDecoration: "underline",
                                    },
                                  }}
                                  onClick={() => {
                                    navigate("/companies/form", {
                                      state: opportunity?.company,
                                    });
                                  }}
                                >
                                  {opportunity?.company?.name}
                                </Box>
                              ) : (
                                "N/A"
                              )}
                            </TableCell>
                            <TableCell>
                              {opportunity?.type?.title || "N/A"}
                            </TableCell>
                            <TableCell>
                              {opportunity?.clientDueDate
                                ? moment(opportunity?.updatedAt).format(
                                    "MM/DD/YYYY"
                                  )
                                : "N/A"}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            <Box sx={{ marginTop: "20px" }}>
              <TableContainer
                component={Box}
                sx={{
                  marginTop: "10px",
                  minHeight: "250px",
                  maxHeight: "380px",
                }}
              >
                <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                  <TableHead className="table-head">
                    <TableRow>
                      <TableCell>Sr. </TableCell>
                      {/* <TableCell>Job# </TableCell> */}
                      <TableCell>Title</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Lost Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {OpportunitySalesSummaryData?.lostOpportunities?.map(
                      (opportunity, index) => {
                        return (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell>{index + 1}</TableCell>
                            {/* <TableCell>{opportunity?.jobNo || "N/A"}</TableCell> */}
                            <TableCell>{opportunity?.title}</TableCell>
                            <TableCell>
                              ${opportunity?.opportunityValue?.toLocaleString()}
                            </TableCell>
                            <TableCell>
                              {opportunity?.company?.name ? (
                                <Box
                                  sx={{
                                    cursor: "pointer",
                                    color: "#551a8b",
                                    "&:hover": {
                                      textDecoration: "underline",
                                    },
                                  }}
                                  onClick={() => {
                                    navigate("/companies/form", {
                                      state: opportunity?.company,
                                    });
                                  }}
                                >
                                  {opportunity?.company?.name}
                                </Box>
                              ) : (
                                "N/A"
                              )}
                            </TableCell>
                            <TableCell>
                              {opportunity?.type?.title || "N/A"}
                            </TableCell>
                            <TableCell>
                              {opportunity?.clientDueDate
                                ? moment(opportunity?.updatedAt).format(
                                    "MM/DD/YYYY"
                                  )
                                : "N/A"}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </CustomTabPanel>
        </Box>
      </Box>

      {showDateRangeDialog && (
        <DateFilter
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          onReset={() => {
            dispatch(
              fetchOpportunitySalesSummaryData({
                startDate: moment().subtract(1, "month").format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
              })
            );
            setStartDate(moment().subtract(1, "month").format("YYYY-MM-DD"));
            setEndDate(moment().format("YYYY-MM-DD"));
          }}
          onClose={() => {
            setShowDateRangeDialog(false);
          }}
          callApi={callOpportunitySummaryApi}
        />
      )}

      {status === "loading" ? <Loader /> : null}
    </>
  );
}
export default SalesSummary;

const useStyles = makeStyles({
  gridContainer: {
    margin: "48px 0",
  },

  statsContainer: {
    padding: "16px",
  },

  infoContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
});
