import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  gridClasses,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import {
  fetchAllOpportunityByStatus,
  fetchOpportunityByIdData,
  fetchOpportunityDocumentsData,
} from "../../../redux/slice/OpportunitySettingsSlice/OpportunitySettingsSlice";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import { useNavigate, useParams } from "react-router-dom";
import ArticleIcon from "@mui/icons-material/Article";
import DocumentsDialog from "../../../components/DocumentsDialog/DocumentsDialog";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { makeStyles } from "@mui/styles";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { SnackBarContext } from "../../../context/SnackBarContext";
import AppConfirmationDialog from "../../../components/ConfirmationDialog/ConfirmationDialog";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { ChangeOpportunityStatusBulk } from "../../../Apis/Opportunity/opportunityApis";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import AddScheduleOnDialog from "../../../components/Opportunity/AddScheduleOnDialog/AddScheduleOnDialog";
import CustomSortFilter from "../../../components/common/CustomSortFilter/CustomSortFilter";

function OpportunityByStatus() {
  const classes = useStyles();

  const [alldata, setalldata] = useState([]);
  const [rows, setrows] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [apiCalling, setApiCalling] = useState(false);
  const [showDocumentDialog, setShowDocumentDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuIdx, setmenuIdx] = useState(null);
  const [showStatusConfirmationDialog, setShowStatusConfirmationDialog] =
    useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [cachedRows, setCachedRows] = useState({});
  const [pageState, setPageState] = useState({
    isLoading: false,
    total: 0,
    page: 1,
    pageSize: 100,
  });

  const [showScheduleOnDialog, setShowScheduleOnDialog] = useState(false);
  const openMenu = Boolean(anchorEl);

  const { setsnackBarData } = useContext(SnackBarContext);

  const navigate = useNavigate();
  const { screen } = useParams();
  const [filter, setFilter] = useState("Default");

  const handleChange = (value) => {
    setFilter(value);
  };

  const dispatch = useDispatch();
  const {
    OpportunityDocumentsData,
    OpportunityByIdData,
    OpportunityQualified,
    status,
    TotalCount,
  } = useSelector((state) => state?.OpportunitySettings);

  useEffect(() => {
    setPageState((old) => ({ ...old, page: 1, pageSize: 100, total: 0 }));
    setCachedRows({});
    callfetchAllOpportunityApi();
  }, [screen, filter]);

  const callfetchAllOpportunityApi = () => {
    if (screen === "on-hold") {
      dispatch(
        fetchAllOpportunityByStatus({
          status: "On Hold",
          page: pageState.page,
          pageSize: pageState.pageSize,
          sort: filter,
        })
      );
    } else if (screen === "awaiting-estimate") {
      dispatch(
        fetchAllOpportunityByStatus({
          status: "Pending",
          page: pageState.page,
          pageSize: pageState.pageSize,
          sort: filter,
        })
      );
    } else if (screen === "estimate-in-progress") {
      dispatch(
        fetchAllOpportunityByStatus({
          status: "In Progress",
          page: pageState.page,
          pageSize: pageState.pageSize,
          sort: filter,
        })
      );
    } else if (screen === "estimate-complete") {
      dispatch(
        fetchAllOpportunityByStatus({
          status: "Under Review",
          page: pageState.page,
          pageSize: pageState.pageSize,
          sort: filter,
        })
      );
    } else if (screen === "open") {
      dispatch(
        fetchAllOpportunityByStatus({
          status: "Open",
          page: pageState.page,
          pageSize: pageState.pageSize,
          sort: filter,
        })
      );
    } else if (screen === "sold") {
      dispatch(
        fetchAllOpportunityByStatus({
          status: "Sold",
          page: pageState.page,
          pageSize: pageState.pageSize,
          sort: filter,
        })
      );
    } else if (screen === "lost") {
      dispatch(
        fetchAllOpportunityByStatus({
          status: "Lost",
          page: pageState.page,
          pageSize: pageState.pageSize,
          sort: filter,
        })
      );
    } else {
      dispatch(
        fetchAllOpportunityByStatus({
          status: "No Opportunity",
          page: pageState.page,
          pageSize: pageState.pageSize,
        })
      );
    }
  };

  useEffect(() => {
    if (OpportunityQualified) {
      setalldata(OpportunityQualified);
    } else {
      setalldata([]);
    }
  }, [OpportunityQualified]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
            padding: "10px",
            width: "100%",
            backgroundColor: "#f5f5f5",
            borderBottom: "1px solid #e0e0e0",
          }}
        >
          <Box>
            <GridToolbarExport />
            <GridToolbarFilterButton />
            <CustomSortFilter
              filter={filter}
              handleChange={handleChange}
            ></CustomSortFilter>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
              Total Amount: ${totalAmount?.toLocaleString()}
            </Typography>

            {screen === "on-hold" && (
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={() => {
                  navigate("form");
                }}
              >
                New
              </Button>
            )}
          </Box>
        </Box>
      </GridToolbarContainer>
    );
  }

  const columns = [
    {
      field: "id",
      headerName: "Sr",
      maxWidth: 30,
      flex: "auto",
      renderCell: (params) => {
        return (pageState.page - 1) * pageState.pageSize + params.value;
      },
    },
    ...(screen !== "on-hold"
      ? [
          {
            field: "jobNo",
            headerName: "Job #",
            maxWidth: 100,
            minWidth: 100,
            flex: 1,
          },
        ]
      : []),
    {
      field: "status",
      headerName: "Status",
      width: 130,
      cellClassName: (params) => {
        switch (params.value) {
          case "On Hold":
            return "status-onHold";
          case "Pending":
            return "status-pending";
          case "In Progress":
            return "status-inProgress";
          case "Open":
            return "status-open";
          case "Lost":
            return "status-lost";
          case "Sold":
            return "status-sold";
          case "No Opportunity":
            return "status-noOpportunity";
          case "Under Review":
            return "status-underReview";
          default:
            return "";
        }
      },
    },
    ...(screen !== "on-hold"
      ? [
          {
            field: "opportunityValue",
            headerName: "Amount",
            minWidth: 150,
          },
        ]
      : []),

    {
      field: "title",
      headerName: "Title",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "company",
      headerName: "Company",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return params?.row?.rowData?.company ? (
          <Box
            sx={{
              cursor: "pointer",
              color: "#551a8b",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            onClick={() => {
              navigate("/companies/form", {
                state: params?.row?.rowData?.company,
              });
            }}
          >
            {params?.row?.company}
          </Box>
        ) : (
          "N/A"
        );
      },
    },
    ...(screen !== "on-hold"
      ? [
          {
            field: "type",
            headerName: "Type",
            minWidth: 120,
            flex: 1,
          },
        ]
      : []),
    ...(screen === "on-hold"
      ? [
          {
            field: "createdAt",
            headerName: "Date Created",
            minWidth: 120,
            flex: 1,
            valueGetter: (params) => {
              const dateValue = params.value;
              return dateValue
                ? moment(dateValue, "MM/DD/YYYY").toISOString()
                : null;
            },
            valueFormatter: (params) => {
              const dateValue = params.value;
              return dateValue
                ? moment(new Date(dateValue), "YYYY-MM-DDTHH:mm:ss").format(
                    "MM/DD/YYYY"
                  )
                : "N/A";
            },
          },
        ]
      : []),
    {
      field: "clientDueDate",
      headerName: "Client Due Date",
      minWidth: 155,
      flex: 1,
      valueGetter: (params) => {
        const dateValue = params.value;
        return dateValue ? moment(dateValue, "MM/DD/YYYY").toISOString() : null;
      },
      valueFormatter: (params) => {
        const dateValue = params.value;
        return dateValue
          ? moment(new Date(dateValue), "YYYY-MM-DDTHH:mm:ss").format(
              "MM/DD/YYYY"
            )
          : "N/A";
      },
    },
    ...(screen == "sold"
      ? [
          {
            field: "updatedAt",
            headerName: "Sold Date",
            minWidth: 155,
            flex: 1,
            valueGetter: (params) => {
              const dateValue = params.value;
              return dateValue
                ? moment(dateValue, "MM/DD/YYYY").toISOString()
                : null;
            },
            valueFormatter: (params) => {
              const dateValue = params.value;
              return dateValue
                ? moment(new Date(dateValue), "YYYY-MM-DDTHH:mm:ss").format(
                    "MM/DD/YYYY"
                  )
                : "N/A";
            },
          },
        ]
      : []),
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 100,
      flex: "auto",
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <Box key={params?.row?.id}>
              <IconButton
                id="basic-button"
                aria-controls={openMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? "true" : undefined}
                onClick={(e) => handleMenuClick(e, params?.row?.id)}
              >
                <Tooltip title="Actions Menu">
                  <MoreVertIcon />
                </Tooltip>
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu && menuIdx === params?.row?.id}
                onClose={handleMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                  sx: { padding: 0 },
                }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                elevation={2}
                PaperProps={{
                  sx: {
                    borderRadius: "12px",
                    padding: "5px",
                  },
                }}
              >
                {/* {screen === "on-hold" && ( */}
                <MenuItem
                  // disabled={screen !== "on-hold"}
                  sx={{ padding: "6px", borderRadius: "8px" }}
                  onClick={() => {
                    handleMenuClose();
                    navigate("form", {
                      state: params?.row?.rowData,
                    });
                  }}
                >
                  <Box className={classes.boxStyle}>
                    <EditIcon className={classes?.iconStyle} color="info" />
                    <Typography className={classes?.itemStyle}>Edit</Typography>
                  </Box>
                </MenuItem>
                {/* )} */}

                <MenuItem
                  sx={{ padding: "6px", borderRadius: "8px" }}
                  onClick={() => {
                    handleMenuClose();
                    setApiCalling(true);
                    setSelectedRow(params?.row?.rowData);
                    dispatch(
                      fetchOpportunityDocumentsData({
                        opportunityId: params?.row?.rowData?.opportunityId,
                        refName: null,
                        refId: null,
                      })
                    );
                  }}
                >
                  <Box className={classes.boxStyle}>
                    <ArticleIcon
                      className={classes?.iconStyle}
                      color="secondary"
                    />
                    <Typography className={classes?.itemStyle}>
                      Documents
                    </Typography>
                  </Box>
                </MenuItem>

                <MenuItem
                  sx={{ padding: "6px", borderRadius: "8px" }}
                  onClick={() => {
                    handleMenuClose();
                    setApiCalling(true);
                    dispatch(
                      fetchOpportunityByIdData(
                        params?.row?.rowData?.opportunityId
                      )
                    );
                  }}
                >
                  <Box className={classes.boxStyle}>
                    <InfoOutlinedIcon
                      className={classes?.iconStyle}
                      color="success"
                    />
                    <Typography className={classes?.itemStyle}>
                      Details
                    </Typography>
                  </Box>
                </MenuItem>
                {screen === "open" && (
                  <MenuItem
                    sx={{ padding: "6px", borderRadius: "8px" }}
                    onClick={() => {
                      handleMenuClose();
                      setSelectedStatus("No Opportunity");
                      setSelectedRow(params?.row?.rowData);
                      setShowStatusConfirmationDialog(true);
                    }}
                  >
                    <Box className={classes.boxStyle}>
                      <WarningOutlinedIcon
                        className={classes?.iconStyle}
                        color="warning"
                      />
                      <Typography className={classes?.itemStyle}>
                        Mark No Opportunity
                      </Typography>
                    </Box>
                  </MenuItem>
                )}
                {screen === "open" && (
                  <MenuItem
                    sx={{ padding: "6px", borderRadius: "8px" }}
                    onClick={() => {
                      handleMenuClose();
                      setSelectedStatus("Sold");
                      setSelectedRow(params?.row?.rowData);
                      setShowStatusConfirmationDialog(true);
                    }}
                  >
                    <Box className={classes.boxStyle}>
                      <CheckCircleIcon
                        className={classes?.iconStyle}
                        color="success"
                      />
                      <Typography className={classes?.itemStyle}>
                        Mark Sold
                      </Typography>
                    </Box>
                  </MenuItem>
                )}

                {screen === "open" && (
                  <MenuItem
                    sx={{ padding: "6px", borderRadius: "8px" }}
                    onClick={() => {
                      handleMenuClose();
                      setSelectedStatus("Lost");
                      setSelectedRow(params?.row?.rowData);
                      setShowStatusConfirmationDialog(true);
                    }}
                  >
                    <Box className={classes.boxStyle}>
                      <CancelIcon
                        className={classes?.iconStyle}
                        color="error"
                      />
                      <Typography className={classes?.itemStyle}>
                        Mark Lost
                      </Typography>
                    </Box>
                  </MenuItem>
                )}

                {screen === "open" && (
                  <MenuItem
                    sx={{ padding: "6px", borderRadius: "8px" }}
                    onClick={() => {
                      handleMenuClose();
                      setSelectedRow(params?.row?.rowData);
                      setShowScheduleOnDialog(true);
                    }}
                  >
                    <Box className={classes.boxStyle}>
                      <WatchLaterIcon
                        className={classes?.iconStyle}
                        color="disabled"
                      />
                      <Typography className={classes?.itemStyle}>
                        Schedule
                      </Typography>
                    </Box>
                  </MenuItem>
                )}
              </Menu>
            </Box>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchData = () => {
      setPageState((old) => ({ ...old, isLoading: true }));
      if (cachedRows[pageState.page]) {
        setPageState((old) => ({ ...old, isLoading: false }));
        return;
      } else {
        try {
          callfetchAllOpportunityApi();
        } catch (error) {
          console.error("Failed to fetch opportunities", error);
        } finally {
          setPageState((old) => ({ ...old, isLoading: false }));
        }
      }
    };
    fetchData();
  }, [pageState.page, pageState.pageSize]);

  React.useEffect(() => {
    let arr = [];
    let amount = 0;
    if (alldata) {
      alldata?.forEach((item, idx) => {
        amount += item?.opportunityValue;
        return arr.push({
          id: idx + 1,
          jobNo: item?.jobNo,
          opportunityValue: item?.opportunityValue
            ? `$${item?.opportunityValue?.toLocaleString()}`
            : "N/A",
          title: item?.title,
          source: item?.opportunitySource?.title,
          description: item?.description,
          contact: item?.contact?.display_name,
          company: item?.company?.name,
          status: item?.status,
          type: item?.type?.title,
          clientDueDate: moment(item?.clientDueDate).format("MM/DD/YYYY"),
          createdAt: moment(item?.createdAt).format("MM/DD/YYYY"),
          updatedAt: moment(item?.updatedAt).format("MM/DD/YYYY"),
          rowData: item,
        });
      });
    }
    setTotalAmount(amount);
    setrows(arr);
  }, [alldata]);

  useEffect(() => {
    setCachedRows((prev) => ({
      ...prev,
      [pageState.page]: rows,
    }));
  }, [rows]);

  useEffect(() => {
    if (apiCalling && OpportunityDocumentsData) {
      setApiCalling(false);
      setShowDocumentDialog(true);
    } else {
      setApiCalling(false);
    }
  }, [OpportunityDocumentsData]);

  useEffect(() => {
    if (apiCalling && OpportunityByIdData) {
      setApiCalling(false);
      navigate(`details`, {
        state: OpportunityByIdData,
      });
    } else {
      setApiCalling(false);
    }
  }, [OpportunityByIdData]);

  const handleMenuClick = (event, idx) => {
    setAnchorEl(event.currentTarget);
    setmenuIdx(idx);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const updateStatus = async () => {
    setLoading(true);
    const response = await ChangeOpportunityStatusBulk({
      status: selectedStatus,
      opportunityIds: [selectedRow?.opportunityId],
    });

    setLoading(false);
    response?.snackBarData?.message && setsnackBarData(response?.snackBarData);

    if (response?.success) {
      setShowStatusConfirmationDialog(false);
      setSelectedStatus("");
      setSelectedRow(null);
      callfetchAllOpportunityApi();
    }
  };

  const rows1 = cachedRows[pageState.page] || [];

  return (
    <>
      <Box className="data-grid-box">
        <DataGrid
          className="data_grid_parent_div"
          rows={rows1}
          pagination
          paginationMode="server"
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: pageState.pageSize,
                page: pageState.page - 1,
              },
            },
          }}
          onPaginationModelChange={(model) => {
            console.log("model", model);
            setPageState((old) => ({ ...old, page: model.page + 1 }));
          }}
          pageSizeOptions={false}
          rowCount={TotalCount}
          loading={pageState.isLoading}
          disableRowSelectionOnClick
          slots={{
            toolbar: CustomToolbar,
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />

        {status === "loading" ? <Loader /> : null}
      </Box>

      <DocumentsDialog
        open={showDocumentDialog}
        onClose={() => {
          setSelectedRow(null);
          setShowDocumentDialog(false);
        }}
        selectedRow={selectedRow}
      />

      <AppConfirmationDialog
        open={showStatusConfirmationDialog}
        onDeny={() => {
          setSelectedStatus("");
          setSelectedRow(null);
          setShowStatusConfirmationDialog(false);
        }}
        onConfirm={() => {
          updateStatus();
        }}
        iconContainerColor="#fff5ea"
        icon={<ChangeCircleIcon fontSize="small" color="primary" />}
        dialogTitle="Update Status"
        title={`Are you sure, you want to Update Status of ${selectedRow?.title}?`}
        successText="Yes"
        successBtnColor="primary"
        cancelText="No"
        loading={loading}
      />

      <AddScheduleOnDialog
        open={showScheduleOnDialog}
        onClose={(callApi) => {
          setSelectedRow(null);
          setShowScheduleOnDialog(false);

          if (callApi) {
            callfetchAllOpportunityApi();
          }
        }}
        selectedRow={selectedRow}
      />
    </>
  );
}
export default OpportunityByStatus;

const useStyles = makeStyles({
  boxStyle: { display: "flex", alignItems: "center", gap: "8px" },
  iconStyle: { fontSize: "1rem !important" },
  itemStyle: {
    fontSize: "0.9rem !important",
    fontWeight: "600 !important",
    color: "rgb(38 50 56 /1)",
  },
});
