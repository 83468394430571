import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import PropTypes from "prop-types";

function LineChart({ fields, data, name }) {
  const [state, setState] = useState({
    series: [
      {
        name: "",
        data: [],
      },
    ],
    options: {
      chart: {
        height: "auto",
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["transparent"],
        },
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value?.toLocaleString();
          },
        },
      },
    },
  });

  useEffect(() => {
    if (fields && data && name) {
      setState({
        series: [
          {
            name,
            data: data,
          },
        ],
        options: {
          ...state.options,
          xaxis: {
            categories: fields,
          },
        },
      });
    }
  }, [fields, data, name]);

  return (
    <div id="chart">
      <Chart
        options={state?.options}
        series={state?.series}
        type="line"
        height="350"
      />
    </div>
  );
}

export default LineChart;

LineChart.propTypes = {
  fields: PropTypes.array,
  data: PropTypes.array,
  name: PropTypes.string,
};
