import { Box, Button, IconButton, Tooltip, gridClasses } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import {
  fetchAllRolesData,
  fetchRolePopulateData,
} from "../../redux/slice/RolesSlice/RolesSlice";
import AddUpdateRoleDialog from "../../components/Settings/Roles/ScreenComponents/AddUpdateRoleDialog/AddUpdateRoleDialog";
import Loader from "../../components/Loader/Loader";

function RolesPage() {
  const [alldata, setalldata] = useState([]);
  const [rows, setrows] = useState([]);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const dispatch = useDispatch();
  const { AllRolesData, status } = useSelector((state) => state?.Roles);

  useEffect(() => {
    dispatch(fetchAllRolesData());
    dispatch(fetchRolePopulateData());
  }, [dispatch]);

  useEffect(() => {
    if (AllRolesData) {
      setalldata(AllRolesData);
    } else {
      setalldata([]);
    }
  }, [AllRolesData]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
            padding: "5px",
            width: "100%",
          }}
        >
          <Box>
            <GridToolbarExport />
            <GridToolbarFilterButton />
          </Box>

          <Box>
            <Button
              color="primary"
              sx={{
                float: "right",
              }}
              variant="contained"
              size="small"
              onClick={() => setShowAddDialog(true)}
            >
              New
            </Button>
          </Box>
        </Box>
      </GridToolbarContainer>
    );
  }

  const columns = [
    { field: "id", headerName: "Sr", maxWidth: 30, flex: "auto" },
    {
      field: "name",
      headerName: "Name",
      minWidth: 170,
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => {
        return moment(params.value, "MM/DD/YYYY").toISOString();
      },
      valueFormatter: (params) => {
        return moment(new Date(params.value), "YYYY-MM-DDTHH:mm:ss a")?.format(
          "MM/DD/YYYY"
        );
      },
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => {
        return moment(params.value, "MM/DD/YYYY").toISOString();
      },
      valueFormatter: (params) => {
        return moment(new Date(params.value), "YYYY-MM-DDTHH:mm:ss a")?.format(
          "MM/DD/YYYY"
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 70,
      flex: "auto",
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Edit">
              <IconButton
                sx={{ padding: "5px" }}
                color="info"
                onClick={() => {
                  setSelectedRow(params?.row);
                  setShowAddDialog(true);
                }}
              >
                <EditIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  React.useEffect(() => {
    let arr = [];
    if (alldata) {
      alldata?.forEach((item, idx) => {
        return arr.push({
          id: idx + 1,
          ...item,
          createdAt: moment(item?.createdAt).format("MM/DD/YYYY"),
          updatedAt: moment(item?.updatedAt).format("MM/DD/YYYY"),
        });
      });
    }
    setrows(arr);
  }, [alldata]);

  return (
    <>
      <Box className="data-grid-box">
        <DataGrid
          className="data_grid_parent_div"
          rows={rows}
          columns={columns}
          pageSize={100}
          pageSizeOptions={false}
          pagination
          components={{
            toolbar: CustomToolbar,
          }}
          disableSelectionOnClick
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />

        {status === "loading" ? <Loader /> : null}
      </Box>

      <AddUpdateRoleDialog
        open={showAddDialog}
        onClose={() => {
          setShowAddDialog(false);
          setSelectedRow(null);
        }}
        selectedRow={selectedRow}
      />
    </>
  );
}
export default RolesPage;
