import { Box, Button, IconButton, Tooltip, gridClasses } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import {
  fetchAllOpportunityData,
  fetchOpportunityByIdData,
  fetchOpportunityDocumentsData,
} from "../../redux/slice/OpportunitySettingsSlice/OpportunitySettingsSlice";
import { useNavigate } from "react-router-dom";
import ArticleIcon from "@mui/icons-material/Article";
import DocumentsDialog from "../../components/DocumentsDialog/DocumentsDialog";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

function Opportunity() {
  const [alldata, setalldata] = useState([]);
  const [rows, setrows] = useState([]);
  const [apiCalling, setApiCalling] = useState(false);
  const [showDocumentDialog, setShowDocumentDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [cachedRows, setCachedRows] = useState({});
  const [pageState, setPageState] = useState({
    isLoading: false,
    total: 0,
    page: 1,
    pageSize: 100,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    AllOpportunitiesData,
    OpportunityDocumentsData,
    OpportunityByIdData,
    status,
    TotalCount,
  } = useSelector((state) => state?.OpportunitySettings);

  useEffect(() => {
    // dispatch(fetchAllOpportunityData());
    dispatch(
      fetchAllOpportunityData({
        page: pageState.page,
        pageSize: pageState.pageSize,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (AllOpportunitiesData) {
      setalldata(AllOpportunitiesData);
    } else {
      setalldata([]);
    }
  }, [AllOpportunitiesData]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
            padding: "10px",
            width: "100%",
            backgroundColor: "#f5f5f5",
            borderBottom: "1px solid #e0e0e0",
          }}
        >
          <Box>
            <GridToolbarExport />
            <GridToolbarFilterButton />
          </Box>
          <Box>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={() => {
                navigate("form");
              }}
            >
              New
            </Button>
          </Box>
        </Box>
      </GridToolbarContainer>
    );
  }

  const columns = [
    {
      field: "id",
      headerName: "Sr",
      maxWidth: 30,
      flex: "auto",
      renderCell: (params) => {
        return (pageState.page - 1) * pageState.pageSize + params.value;
      },
    },
    {
      field: "title",
      headerName: "Title",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "source",
      headerName: "Source",
      minWidth: 110,
      flex: 1,
    },
    {
      field: "contact",
      headerName: "Contact",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              cursor: "pointer",
              color: "#551a8b",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            onClick={() => {
              navigate("/contacts/form", {
                state: params?.row?.rowData?.contact,
              });
            }}
          >
            {params?.row?.contact}
          </Box>
        );
      },
    },
    {
      field: "company",
      headerName: "Company",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              cursor: "pointer",
              color: "#551a8b",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            onClick={() => {
              navigate("/companies/form", {
                state: params?.row?.rowData?.company,
              });
            }}
          >
            {params?.row?.company}
          </Box>
        );
      },
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 130,

      cellClassName: (params) => {
        switch (params.value) {
          case "On Hold":
            return "status-onHold";
          case "Pending":
            return "status-pending";
          case "In Progress":
            return "status-inProgress";
          case "Open":
            return "status-open";
          case "Lost":
            return "status-lost";
          case "Sold":
            return "status-sold";
          case "No Opportunity":
            return "status-noOpportunity";
          case "Under Review":
            return "status-underReview";
          default:
            return "";
        }
      },
    },
    {
      field: "clientDueDate",
      headerName: "Client Due Date",
      minWidth: 155,
      flex: 1,
      valueGetter: (params) => {
        const dateValue = params.value;
        return dateValue ? moment(dateValue, "MM/DD/YYYY").toISOString() : null;
      },
      valueFormatter: (params) => {
        const dateValue = params.value;
        return dateValue
          ? moment(new Date(dateValue), "YYYY-MM-DDTHH:mm:ss").format(
              "MM/DD/YYYY"
            )
          : "N/A";
      },
    },
    {
      field: "address",
      headerName: "Project Location",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "clientDueDate",
      headerName: "Bid Due Date",
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => {
        return moment(params.value, "Do MMM-YY ").toISOString();
      },
      valueFormatter: (params) => {
        return moment(new Date(params.value), "YYYY-MM-DDTHH:mm:ss a")?.format(
          "Do MMM-YY "
        );
      },
    },
    {
      field: "bidLink",
      headerName: "Invitation Link",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "contact.display_name",
      headerName: "Contact Name",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "contact.phone",
      headerName: "Invitation Link",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => {
        return moment(params.value, "Do MMM-YY ").toISOString();
      },
      valueFormatter: (params) => {
        return moment(new Date(params.value), "YYYY-MM-DDTHH:mm:ss a")?.format(
          "Do MMM-YY "
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 100,
      flex: "auto",
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <Tooltip title="Documents">
              <IconButton
                sx={{ padding: "5px" }}
                color="secondary"
                onClick={() => {
                  setApiCalling(true);
                  setSelectedRow(params?.row?.rowData);
                  dispatch(
                    fetchOpportunityDocumentsData({
                      opportunityId: params?.row?.rowData?.opportunityId,
                      refName: null,
                      refId: null,
                    })
                  );
                }}
              >
                <ArticleIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Edit">
              <IconButton
                sx={{ padding: "5px" }}
                color="info"
                onClick={() => {
                  navigate("form", {
                    state: params?.row?.rowData,
                  });
                }}
              >
                <EditIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="View Details">
              <IconButton
                sx={{ padding: "5px" }}
                color="success"
                onClick={() => {
                  setApiCalling(true);
                  dispatch(
                    fetchOpportunityByIdData(
                      params?.row?.rowData?.opportunityId
                    )
                  );
                }}
              >
                <InfoOutlinedIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  React.useEffect(() => {
    let arr = [];
    if (alldata) {
      alldata?.forEach((item, idx) => {
        return arr.push({
          id: idx + 1,
          title: item?.title,
          source: item?.opportunitySource?.title,
          description: item?.description,
          contact: item?.contact?.display_name,
          company: item?.company?.name,
          status: item?.status,
          type: item?.type?.title,
          clientDueDate: moment(item?.clientDueDate).format("Do MMM-YY "),
          createdAt: moment(item?.createdAt).format("Do MMM-YY "),
          updatedAt: moment(item?.updatedAt).format("Do MMM-YY "),
          rowData: item,
        });
      });
    }
    setrows(arr);
  }, [alldata]);

  useEffect(() => {
    if (apiCalling && OpportunityDocumentsData) {
      setApiCalling(false);
      setShowDocumentDialog(true);
    } else {
      setApiCalling(false);
    }
  }, [OpportunityDocumentsData]);

  useEffect(() => {
    if (apiCalling && OpportunityByIdData) {
      setApiCalling(false);
      navigate("/bidding/details", {
        state: OpportunityByIdData,
      });
    } else {
      setApiCalling(false);
    }
  }, [OpportunityByIdData]);

  useEffect(() => {
    const fetchData = () => {
      setPageState((old) => ({ ...old, isLoading: true }));
      if (cachedRows[pageState.page]) {
        setPageState((old) => ({ ...old, isLoading: false }));
        return;
      } else {
        try {
          dispatch(
            fetchAllOpportunityData({
              page: pageState.page,
              pageSize: pageState.pageSize,
            })
          );
        } catch (error) {
          console.error("Failed to fetch opportunities", error);
        } finally {
          setPageState((old) => ({ ...old, isLoading: false }));
        }
      }
    };
    fetchData();
  }, [pageState.page, pageState.pageSize]);

  useEffect(() => {
    setCachedRows((prev) => ({
      ...prev,
      [pageState.page]: rows,
    }));
  }, [rows]);

  const rows1 = cachedRows[pageState.page] || [];
  return (
    <>
      <Box className="data-grid-box">
        <DataGrid
          className="data_grid_parent_div"
          rows={rows1}
          pagination
          paginationMode="server"
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: pageState.pageSize,
                page: pageState.page - 1,
              },
            },
          }}
          onPaginationModelChange={(model) => {
            console.log("model", model);
            setPageState((old) => ({ ...old, page: model.page + 1 }));
          }}
          pageSizeOptions={false}
          rowCount={TotalCount}
          loading={pageState.isLoading}
          disableRowSelectionOnClick
          slots={{
            toolbar: CustomToolbar,
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />

        {status === "loading" ? <Loader /> : null}
      </Box>

      <DocumentsDialog
        open={showDocumentDialog}
        onClose={() => {
          setSelectedRow(null);
          setShowDocumentDialog(false);
        }}
        selectedRow={selectedRow}
      />
    </>
  );
}

export default Opportunity;
