import { Box, Button, IconButton, Tooltip, gridClasses } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import AddUpdateDialog from "../../../components/Settings/Opportunity/ScreenComponents/AddUpdateDialog/AddUpdateDialog";
import Loader from "../../../components/Loader/Loader";
import { fetchProjectTypesData } from "../../../redux/slice/OpportunitySettingsSlice/OpportunitySettingsSlice";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { SnackBarContext } from "../../../context/SnackBarContext";
import { DeleteProjectTypeAPi } from "../../../Apis/OpportunitySettings/OpportunitySettingsApis";
import AppConfirmationDialog from "../../../components/ConfirmationDialog/ConfirmationDialog";

function ProjectTypes() {
  const [alldata, setalldata] = useState([]);
  const [rows, setrows] = useState([]);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const dispatch = useDispatch();
  const { TypesData, status } = useSelector(
    (state) => state?.OpportunitySettings
  );
  const { setsnackBarData } = useContext(SnackBarContext);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchProjectTypesData());
  }, [dispatch]);

  useEffect(() => {
    if (TypesData) {
      setalldata(TypesData);
    } else {
      setalldata([]);
    }
  }, [TypesData]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
            padding: "5px",
            width: "100%",
          }}
        >
          <Box>
            <GridToolbarExport />
            <GridToolbarFilterButton />
          </Box>

          <Box>
            <Button
              color="primary"
              sx={{
                float: "right",
              }}
              variant="contained"
              size="small"
              onClick={() => setShowAddDialog(true)}
            >
              New
            </Button>
          </Box>
        </Box>
      </GridToolbarContainer>
    );
  }
  const deleteType = async () => {
    setLoading(true);
    setShowConfirmation(false);

    const response = await DeleteProjectTypeAPi(selectedRow?.typeId);

    response?.snackBarData?.message && setsnackBarData(response?.snackBarData);

    if (response?.success) {
      dispatch(fetchProjectTypesData());
      setSelectedRow(null);
    }
    setLoading(false);
  };
  const columns = [
    { field: "id", headerName: "Sr", maxWidth: 30 },
    {
      field: "title",
      headerName: "Title",
      minWidth: 170,
    },

    // {
    //   field: "createdAt",
    //   headerName: "Created At",
    //   minWidth: 120,
    //   flex: 1,
    //   valueGetter: (params) => {
    //     return moment(params.value, "MM/DD/YYYY").toISOString();
    //   },
    //   valueFormatter: (params) => {
    //     return moment(new Date(params.value), "YYYY-MM-DDTHH:mm:ss a")?.format(
    //       "MM/DD/YYYY"
    //     );
    //   },
    // },
    // {
    //   field: "updatedAt",
    //   headerName: "Updated At",
    //   minWidth: 120,
    //   flex: 1,
    //   valueGetter: (params) => {
    //     return moment(params.value, "MM/DD/YYYY").toISOString();
    //   },
    //   valueFormatter: (params) => {
    //     return moment(new Date(params.value), "YYYY-MM-DDTHH:mm:ss a")?.format(
    //       "MM/DD/YYYY"
    //     );
    //   },
    // },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 70,

      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Edit">
              <IconButton
                sx={{ padding: "5px" }}
                color="info"
                onClick={() => {
                  setSelectedRow(params?.row);
                  setShowAddDialog(true);
                }}
              >
                <EditIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                sx={{ padding: "5px" }}
                color="error"
                onClick={() => {
                  setSelectedRow(params?.row);
                  setShowConfirmation(true);
                }}
              >
                <DeleteOutlineIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  React.useEffect(() => {
    let arr = [];
    if (alldata) {
      alldata?.forEach((item, idx) => {
        return arr.push({
          id: idx + 1,
          ...item,
        });
      });
    }
    setrows(arr);
  }, [alldata]);

  return (
    <>
      <Box className="data-grid-box">
        <DataGrid
          className="data_grid_parent_div"
          rows={rows}
          columns={columns}
          pageSize={100}
          pageSizeOptions={false}
          pagination
          components={{
            toolbar: CustomToolbar,
          }}
          disableSelectionOnClick
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />

        {status === "loading" || loading ? <Loader /> : null}
      </Box>

      <AddUpdateDialog
        open={showAddDialog}
        onClose={() => {
          setShowAddDialog(false);
          setSelectedRow(null);
        }}
        selectedRow={selectedRow}
        heading="Type"
        onSuccess={() => {
          dispatch(fetchProjectTypesData());
        }}
      />
      {showConfirmation && (
        <AppConfirmationDialog
          open={showConfirmation}
          onDeny={() => {
            setSelectedRow(null);
            setShowConfirmation(false);
          }}
          onConfirm={() => {
            deleteType();
          }}
          iconContainerColor="#fbeaea"
          icon={
            <DeleteOutlineIcon
              fontSize="small"
              sx={{ color: (theme) => theme?.palette?.error?.main }}
            />
          }
          dialogTitle="Delete Project Type"
          title={`Are you sure, you want to delete project type ${selectedRow?.title}?`}
          successText="Yes"
          successBtnColor="error"
          cancelText="No"
        />
      )}
    </>
  );
}
export default ProjectTypes;
