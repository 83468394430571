import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  SearchData: [],
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const fetchSearchData = createAsyncThunk(
  "/Opportunity/SearchLead",
  async (payload) => {
    console.log("payload", payload);
    const response = await axiosInstance.post(
      `/Opportunity/SearchLead`,
      payload
    );
    return response?.data;
  }
);

const SearchSlice = createSlice({
  name: "SearchSlice",
  initialState,
  reducers: {
    // You can add more reducers here
    resetSearchData: (state) => {
      state.SearchData = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchSearchData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSearchData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.SearchData = action.payload.data;
      })
      .addCase(fetchSearchData.rejected, (state, action) => {
        state.SearchData = [];
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

// Export actions
export const { resetSearchData } = SearchSlice.actions;
export default SearchSlice.reducer;
