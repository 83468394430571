import React, { useState } from "react";
import { Button, Menu, MenuItem, Badge } from "@mui/material";
import PropTypes from "prop-types";
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
const CustomSortFilter = ({ filter, handleChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value) => {
    setAnchorEl(null);
    if (value) handleChange(value);
  };

  return (
    <>
      <Badge color="primary" badgeContent={filter !== "Default" ? filter : null}>
        <Button
          aria-controls={anchorEl ? "custom-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={Boolean(anchorEl)}
          onClick={handleClick}
          variant="text"
          color="primary"
          size="small"
          startIcon={<SwapVertOutlinedIcon />}
        >
          Sort
        </Button>
      </Badge>
      <Menu
        id="custom-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleClose()}
      >
        <MenuItem onClick={() => handleClose("Highest")}>Highest</MenuItem>
        <MenuItem onClick={() => handleClose("Lowest")}>Lowest</MenuItem>
        <MenuItem onClick={() => handleClose("Default")}>Default</MenuItem>
      </Menu>
    </>
  );
};

CustomSortFilter.propTypes = {
  filter: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  badgeCount: PropTypes.number, // New prop for badge count
};

export default CustomSortFilter;
