import React, { useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  ListItem,
  MenuItem,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { FieldArray, Form, Formik } from "formik";
import * as yup from "yup";
import Loader from "../../../components/Loader/Loader";
import { fetchOpportunityPopulateData } from "../../../redux/slice/OpportunitySettingsSlice/OpportunitySettingsSlice";
import { makeStyles } from "@mui/styles";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import {
  CreateOpportunityAPi,
  UpdateOpportunityAPi,
} from "../../../Apis/OpportunitySettings/OpportunitySettingsApis";
import { SnackBarContext } from "../../../context/SnackBarContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from "draft-convert";
import { EditorState, RichUtils, getDefaultKeyBinding } from "draft-js";
import "draft-js/dist/Draft.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ContactsFormDialog from "../../../components/Contacts/ContactsFormDialog/ContactsFormDialog";
import AddUpdateDialog from "../../../components/Settings/Opportunity/ScreenComponents/AddUpdateDialog/AddUpdateDialog";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";
import CompaniesFormDialog from "../../../components/Companies/CompaniesFormDialog/CompaniesFormDialog";
import { City, State } from "country-state-city";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";

const validationSchema = yup.object({
  jobNo: yup.string(),
  contactId: yup.string().required("Required Field!"),
  title: yup.string().required("Required Field!"),
  sourceId: yup.string().required("Required Field!"),
  typeId: yup.string().required("Required Field!"),
  // address: yup.string().required("Required Field!"),
  city: yup.string().when("cities", {
    is: (cities) => cities.length,
    then: () => yup.string().required("Required Field!"),
    otherwise: () => yup.string(),
  }),
  state: yup.string().required("Required Field!"),
  // zipcode: yup.string().required("Required Field!"),
  companyId: yup.string().required("Required Field!"),
  // confidence: yup.string().required("Required Field!"),
  // description: yup.string().required("Required Field!"),
  clientDueDate: yup
    .date()
    .required("Required Field!")
    .typeError("Invalid Date"),
  internalDueDate: yup
    .date()
    .required("Required Field!")
    .typeError("Invalid Date!")
    .test(
      "is-before-client-due-date",
      "Internal Due Date must be before Client Due Date!",
      function (value) {
        const { clientDueDate } = this.parent;
        return (
          clientDueDate && value && new Date(value) < new Date(clientDueDate)
        );
      }
    ),
  // opportunityValue: yup.string().required("Required Field!"),
  // bidLink: yup.string().url("Invalid URL").required("Required Field!"),
});

function OpportunityForm() {
  const classes = useStyles();

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [note, setNotes] = useState("");
  const [showContactDialog, setShowContactDialog] = useState(false);
  const [contactDialogFor, setContactDialogFor] = useState("second");
  const [showCompanyDialog, setShowCompanyDialog] = useState(false);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [heading, setHeading] = useState("");
  const [states, setStates] = useState([]);

  const { setsnackBarData } = useContext(SnackBarContext);

  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { OpportunitiesPopulateData, status } = useSelector(
    (state) => state?.OpportunitySettings
  );

  useEffect(() => {
    if (dispatch) {
      dispatch(fetchOpportunityPopulateData());
    }
    let value = State.getStatesOfCountry("US");
    value = value?.map((data) => {
      let obj = {
        label: data?.name,
        value: data?.isoCode,
      };
      return obj;
    });
    setStates(value);
  }, [dispatch]);

  useEffect(() => {
    let html = convertToHTML(editorState && editorState.getCurrentContent());

    if (html !== "<p></p>") {
      setNotes(html);
    }
  }, [editorState]);

  const onChange = async (value) => {
    setEditorState(value);
  };

  const handleKeyCommand = (command, editorState) => {
    // Intercept Enter key press
    if (command === "split-block") {
      // Create a new EditorState with a soft line break instead of a new paragraph
      const newState = RichUtils.insertSoftNewline(editorState);
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const mapKeyToEditorCommand = (e) => {
    // Handle Enter key press with shift
    if (e.keyCode === 13 /* 'Enter' key */ && e.shiftKey) {
      return "split-block";
    }
    return getDefaultKeyBinding(e);
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    console.log("handleSubmit", values);
    let formData = new FormData();
    formData.append("jobNo", values?.jobNo?.trim());
    formData.append("title", values?.title?.trim());
    formData.append(`status`, values?.status || "On Hold");
    formData.append("contactId", values?.contactId);
    formData.append("sourceId", values?.sourceId);
    formData.append("typeId", values?.typeId);
    formData.append("address", values?.address);
    formData.append("state", values?.state);
    formData.append("zipcode", values?.zipcode);
    formData.append("companyId", values?.companyId);
    formData.append("clientDueDate", values?.clientDueDate);
    formData.append("internalDueDate", values?.internalDueDate);
    if (values?.confidence) {
      formData.append("confidence", values?.confidence);
    }
    if (values?.bidLink) {
      formData.append("bidLink", values?.bidLink);
    }
    if (values?.description) {
      formData.append("description", values?.description);
    }
    if (values?.tagId?.length) {
      formData.append(`tagId`, JSON.stringify(values?.tagId));
    }

    if (values?.gcIds?.length) {
      formData.append(`gcIds`, JSON.stringify(values?.gcIds));
    }

    if (values?.city) {
      formData.append("city", values?.city);
    }

    if (values?.assignedTo) {
      formData.append("assignedTo", values?.assignedTo);
    }

    if (note) {
      formData.append("note", note);
    }

    if (values?.projectsize) {
      formData.append("projectsize", values?.projectsize);
    }
    if (values?.opportunityValue) {
      formData.append("opportunityValue", values?.opportunityValue);
    }

    let types = [];
    values?.documents?.forEach((document) => {
      if (document.type && document.file) {
        types.push(document?.type);
      }
    });

    if (types?.length) {
      formData.append(`types`, JSON.stringify(types));
    }

    for (let i = 0; i < values?.documents?.length; i++) {
      if (values?.documents[i].type && values?.documents[i].file) {
        formData.append(`documents`, values?.documents[i].file?.file);
      }
    }

    let response;

    if (state) {
      response = await UpdateOpportunityAPi(state?.opportunityId, formData);
    } else {
      response = await CreateOpportunityAPi(formData);
    }
    setSubmitting(false);

    response?.snackBarData?.message && setsnackBarData(response?.snackBarData);

    if (response?.success) {
      if (!state) {
        setEditorState(EditorState.createEmpty());
        resetForm();
      }
      navigate(-1);
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "12px",
          boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
          width: "100%",
          height: "calc(100vh - 100px)",
          overflowY: "scroll",
          padding: "1.5rem",
        }}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            jobNo: state?.jobNo || "",
            contactId: state?.contactId || "",
            title: state?.title || "",
            sourceId: state?.sourceId || "",
            typeId: state?.typeId || "",
            tagId: state?.tags?.map((tag) => tag?.tagId) || [],
            gcIds: state?.contacts?.map((gc) => gc?.contactId) || [],
            assignedTo: state?.assignedTo || "",
            address: state?.address || "",
            cities: [],
            city: state?.city || "",
            state: state?.state || "",
            status: state?.status || "",
            zipcode: state?.zipcode || "",
            note: "",
            companyId: state?.companyId || "",
            projectsize: state?.projectsize || "",
            description: state?.description || "",
            confidence: state?.confidence || "",
            clientDueDate: state?.clientDueDate
              ? moment(state?.clientDueDate).format("YYYY-MM-DD")
              : "",
            internalDueDate: state?.internalDueDate
              ? moment(state?.internalDueDate).format("YYYY-MM-DD")
              : "",
            opportunityValue: state?.opportunityValue || "",
            bidLink: state?.bidLink || "",
            documents: [],
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            handleChange,
            setFieldValue,
            handleBlur,
            errors,
            touched,
            isSubmitting,
          }) => {
            return (
              <>
                <Form>
                  <Box>
                    <Typography className={classes?.fieldsHeading}>
                      General
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Job Number"
                          variant="outlined"
                          type="text"
                          fullWidth
                          size="small"
                          name="jobNo"
                          value={values.jobNo}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors?.jobNo && touched?.jobNo}
                          helperText={
                            errors?.jobNo && touched?.jobNo ? errors?.jobNo : ""
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Autocomplete
                            options={OpportunitiesPopulateData?.contacts || []}
                            getOptionLabel={(option) => option.display_name}
                            renderOption={(props, option) => {
                              return (
                                <ListItem {...props} key={option?.contactId}>
                                  <Typography>
                                    {option?.display_name} ({option?.phone})
                                  </Typography>
                                </ListItem>
                              );
                            }}
                            fullWidth
                            value={
                              OpportunitiesPopulateData?.contacts?.find(
                                (item) => item?.contactId === values.contactId
                              ) || null
                            }
                            onChange={(event, value) => {
                              if (value) {
                                setFieldValue("contactId", value?.contactId);
                              } else {
                                setFieldValue("contactId", "");
                              }
                            }}
                            filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Contact"
                                size="small"
                                name="contactId"
                                onBlur={handleBlur}
                                error={errors?.contactId && touched?.contactId}
                                helperText={
                                  errors?.contactId && touched?.contactId
                                    ? errors?.contactId
                                    : ""
                                }
                              />
                            )}
                          />

                          <Tooltip title="Add New Contact">
                            <IconButton
                              sx={{ padding: "5px" }}
                              color="primary"
                              onClick={() => {
                                setContactDialogFor("contact");
                                setShowContactDialog(true);
                              }}
                            >
                              <AddCircleOutlineIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Opportunity Title"
                          variant="outlined"
                          type="text"
                          fullWidth
                          size="small"
                          name="title"
                          value={values.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors?.title && touched?.title}
                          helperText={
                            errors?.title && touched?.title ? errors?.title : ""
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Autocomplete
                            options={OpportunitiesPopulateData?.sources || []}
                            getOptionLabel={(option) => option.title}
                            fullWidth
                            value={
                              OpportunitiesPopulateData?.sources?.find(
                                (item) => item?.sourceId === values.sourceId
                              ) || null
                            }
                            onChange={(event, value) => {
                              if (value) {
                                setFieldValue("sourceId", value?.sourceId);
                              } else {
                                setFieldValue("sourceId", "");
                              }
                            }}
                            filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Source"
                                size="small"
                                name="sourceId"
                                onBlur={handleBlur}
                                error={errors?.sourceId && touched?.sourceId}
                                helperText={
                                  errors?.sourceId && touched?.sourceId
                                    ? errors?.sourceId
                                    : ""
                                }
                              />
                            )}
                          />

                          <Tooltip title="Add New Source">
                            <IconButton
                              sx={{ padding: "5px" }}
                              color="primary"
                              onClick={() => {
                                setHeading("Source");
                                setShowAddDialog(true);
                              }}
                            >
                              <AddCircleOutlineIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Autocomplete
                            options={
                              OpportunitiesPopulateData?.projectTypes || []
                            }
                            getOptionLabel={(option) => option.title}
                            fullWidth
                            value={
                              OpportunitiesPopulateData?.projectTypes?.find(
                                (item) => item?.typeId === values.typeId
                              ) || null
                            }
                            onChange={(event, value) => {
                              if (value) {
                                setFieldValue("typeId", value?.typeId);
                              } else {
                                setFieldValue("typeId", "");
                              }
                            }}
                            filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Project Type"
                                size="small"
                                name="typeId"
                                onBlur={handleBlur}
                                error={errors?.typeId && touched?.typeId}
                                helperText={
                                  errors?.typeId && touched?.typeId
                                    ? errors?.typeId
                                    : ""
                                }
                              />
                            )}
                          />
                          <Tooltip title="Add New Project Type">
                            <IconButton
                              sx={{ padding: "5px" }}
                              color="primary"
                              onClick={() => {
                                setHeading("Type");
                                setShowAddDialog(true);
                              }}
                            >
                              <AddCircleOutlineIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Autocomplete
                            multiple
                            options={OpportunitiesPopulateData?.tags || []}
                            getOptionLabel={(option) => option.title}
                            fullWidth
                            value={
                              OpportunitiesPopulateData?.tags?.filter((item) =>
                                values.tagId?.includes(item?.tagId)
                              ) || []
                            }
                            onChange={(event, value) => {
                              if (value?.length) {
                                let tagIds = value?.map((tag) => tag?.tagId);
                                setFieldValue("tagId", tagIds);
                              } else {
                                setFieldValue("tagId", []);
                              }
                            }}
                            filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Tags"
                                size="small"
                                name="tagId"
                                onBlur={handleBlur}
                                error={errors?.tagId && touched?.tagId}
                                helperText={
                                  errors?.tagId && touched?.tagId
                                    ? errors?.tagId
                                    : ""
                                }
                              />
                            )}
                          />

                          <Tooltip title="Add New Tag">
                            <IconButton
                              sx={{ padding: "5px" }}
                              color="primary"
                              onClick={() => {
                                setHeading("Tag");
                                setShowAddDialog(true);
                              }}
                            >
                              <AddCircleOutlineIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Autocomplete
                            multiple
                            options={
                              OpportunitiesPopulateData?.Generalcontractors ||
                              []
                            }
                            getOptionLabel={(option) => option.display_name}
                            renderOption={(props, option) => {
                              return (
                                <ListItem {...props} key={option?.contactId}>
                                  <Typography>
                                    {option?.display_name} ({option?.phone})
                                  </Typography>
                                </ListItem>
                              );
                            }}
                            fullWidth
                            value={
                              OpportunitiesPopulateData?.Generalcontractors?.filter(
                                (item) =>
                                  values.gcIds?.includes(item?.contactId)
                              ) || []
                            }
                            onChange={(event, value) => {
                              if (value?.length) {
                                let ids = value?.map((tag) => tag?.contactId);
                                setFieldValue("gcIds", ids);
                              } else {
                                setFieldValue("gcIds", []);
                              }
                            }}
                            filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="General Contractor"
                                size="small"
                                name="gcIds"
                                onBlur={handleBlur}
                                error={errors?.gcIds && touched?.gcIds}
                                helperText={
                                  errors?.gcIds && touched?.gcIds
                                    ? errors?.gcIds
                                    : ""
                                }
                              />
                            )}
                          />

                          <Tooltip title="Add New General Contractor">
                            <IconButton
                              sx={{ padding: "5px" }}
                              color="primary"
                              onClick={() => {
                                setContactDialogFor("GC");
                                setShowContactDialog(true);
                              }}
                            >
                              <AddCircleOutlineIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Autocomplete
                          options={OpportunitiesPopulateData?.users || []}
                          getOptionLabel={(option) => option.name}
                          value={
                            OpportunitiesPopulateData?.users?.find(
                              (item) => item?.userId === values.assignedTo
                            ) || null
                          }
                          onChange={(event, value) => {
                            if (value) {
                              setFieldValue("assignedTo", value?.userId);
                            } else {
                              setFieldValue("assignedTo", "");
                            }
                          }}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Assigned To"
                              size="small"
                              fullWidth
                              name="assignedTo"
                              onBlur={handleBlur}
                              error={errors?.assignedTo && touched?.assignedTo}
                              helperText={
                                errors?.assignedTo && touched?.assignedTo
                                  ? errors?.assignedTo
                                  : ""
                              }
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box sx={{ marginTop: "20px" }}>
                    <Typography className={classes?.fieldsHeading}>
                      Address
                    </Typography>
                    <Divider sx={{ mb: 2 }} />

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          label="Address"
                          variant="outlined"
                          type="text"
                          fullWidth
                          size="small"
                          name="address"
                          multiline
                          rows={3}
                          value={values.address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors?.address && touched?.address}
                          helperText={
                            errors?.address && touched?.address
                              ? errors?.address
                              : ""
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={4}>
                        <Autocomplete
                          options={states || []}
                          getOptionLabel={(option) => option?.label}
                          fullWidth
                          value={
                            states?.find(
                              (item) => item.label === values.state
                            ) || null
                          }
                          onChange={(event, val) => {
                            if (val) {
                              setFieldValue("state", val?.label);

                              let cities = City.getCitiesOfState(
                                "US",
                                val?.value
                              );
                              cities = cities?.map((data) => {
                                return data?.name;
                              });
                              if (val?.value == "FL") {
                                cities.push("Parrish", "Myakka");
                              }
                              setFieldValue("cities", cities);
                            } else {
                              setFieldValue("state", "");
                            }
                            setFieldValue("city", "");
                          }}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="State"
                              size="small"
                              name="state"
                              onBlur={handleBlur}
                              error={errors?.state && touched?.state}
                              helperText={
                                errors?.state && touched?.state
                                  ? errors?.state
                                  : ""
                              }
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={4}>
                        <Autocomplete
                          options={values.cities || []}
                          getOptionLabel={(option) => option}
                          fullWidth
                          value={values.city || null}
                          onChange={(event, val) => {
                            if (val) {
                              setFieldValue("city", val);
                            } else {
                              setFieldValue("city", "");
                            }
                          }}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="City"
                              size="small"
                              name="city"
                              onBlur={handleBlur}
                              error={errors?.city && touched?.city}
                              helperText={
                                errors?.city && touched?.city
                                  ? errors?.city
                                  : ""
                              }
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          label="Zip Code"
                          variant="outlined"
                          type="number"
                          fullWidth
                          size="small"
                          name="zipcode"
                          value={values.zipcode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors?.zipcode && touched?.zipcode}
                          helperText={
                            errors?.zipcode && touched?.zipcode
                              ? errors?.zipcode
                              : ""
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  {!state && (
                    <Box sx={{ marginTop: "20px" }}>
                      <Typography className={classes?.fieldsHeading}>
                        Notes
                      </Typography>
                      <Divider sx={{ mb: 2 }} />

                      <FormControl fullWidth>
                        <Box
                          sx={{
                            maxHeight: "500px",
                            overflow: "auto",
                            border:
                              Boolean(errors.body) && Boolean(touched.body)
                                ? "1px solid #d32f2f"
                                : "1px solid #bdbdbd",
                            padding: "8px",
                          }}
                        >
                          <Editor
                            placeholder="Start Typing your note here"
                            editorState={editorState}
                            handleKeyCommand={handleKeyCommand}
                            keyBindingFn={mapKeyToEditorCommand}
                            onEditorStateChange={(value) => {
                              onChange(value);
                            }}
                          />
                        </Box>
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {Boolean(errors.body) && Boolean(touched.body)
                            ? "Field Required!"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  )}

                  <Box sx={{ marginTop: "20px" }}>
                    <Typography className={classes?.fieldsHeading}>
                      Client
                    </Typography>
                    <Divider sx={{ mb: 2 }} />

                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Autocomplete
                            options={OpportunitiesPopulateData?.companies || []}
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option) => {
                              return (
                                <ListItem {...props} key={option?.companyId}>
                                  <Typography>
                                    {option?.name} ({option?.phone})
                                  </Typography>
                                </ListItem>
                              );
                            }}
                            fullWidth
                            value={
                              OpportunitiesPopulateData?.companies?.find(
                                (item) => item?.companyId === values.companyId
                              ) || null
                            }
                            onChange={(event, value) => {
                              if (value) {
                                setFieldValue("companyId", value?.companyId);
                              } else {
                                setFieldValue("companyId", "");
                              }
                            }}
                            filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Company"
                                size="small"
                                name="companyId"
                                onBlur={handleBlur}
                                error={errors?.companyId && touched?.companyId}
                                helperText={
                                  errors?.companyId && touched?.companyId
                                    ? errors?.companyId
                                    : ""
                                }
                              />
                            )}
                          />

                          <Tooltip title="Add New Company">
                            <IconButton
                              sx={{ padding: "5px" }}
                              color="primary"
                              onClick={() => {
                                setShowCompanyDialog(true);
                              }}
                            >
                              <AddCircleOutlineIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Project Size"
                          variant="outlined"
                          type="number"
                          fullWidth
                          size="small"
                          name="projectsize"
                          value={values.projectsize}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors?.projectsize && touched?.projectsize}
                          helperText={
                            errors?.projectsize && touched?.projectsize
                              ? errors?.projectsize
                              : ""
                          }
                        />
                      </Grid>

                      <Grid item xs={12} container>
                        <Grid item xs={12} md={6}>
                          <FormLabel>Confidence</FormLabel>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <Slider
                              aria-label="Confidence"
                              getAriaValueText={(value) => {
                                return `${value}%`;
                              }}
                              sx={{ margin: "10px 0 0 10px" }}
                              color={
                                errors?.confidence && touched?.confidence
                                  ? "error"
                                  : "primary"
                              }
                              min={0}
                              max={100}
                              valueLabelDisplay="auto"
                              name="confidence"
                              value={Number(values.confidence)}
                              onChange={handleChange}
                            />

                            <Typography sx={{ fontWeight: "bold" }}>
                              {values.confidence ? `${values.confidence}%` : ""}
                            </Typography>
                          </Box>
                          <FormHelperText sx={{ color: "#d32f2f" }}>
                            {errors?.confidence && touched?.confidence
                              ? errors?.confidence
                              : ""}
                          </FormHelperText>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          label="Description"
                          variant="outlined"
                          type="text"
                          fullWidth
                          multiline
                          rows={5}
                          size="small"
                          name="description"
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors?.description && touched?.description}
                          helperText={
                            errors?.description && touched?.description
                              ? errors?.description
                              : ""
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Client Due Date"
                          variant="outlined"
                          type="date"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          name="clientDueDate"
                          value={values.clientDueDate}
                          onChange={(e) => {
                            setFieldValue("clientDueDate", e.target.value);
                            setFieldValue(
                              "internalDueDate",
                              moment(e.target.value)
                                .subtract(5, "days")
                                .format("YYYY-MM-DD")
                            );
                          }}
                          onBlur={handleBlur}
                          error={
                            errors?.clientDueDate && touched?.clientDueDate
                          }
                          helperText={
                            errors?.clientDueDate && touched?.clientDueDate
                              ? errors?.clientDueDate
                              : ""
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Internal Due Date"
                          variant="outlined"
                          type="date"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          name="internalDueDate"
                          inputProps={{
                            max: moment(values?.clientDueDate)
                              .subtract(1, "day")
                              .format("YYYY-MM-DD"),
                          }}
                          value={values.internalDueDate}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            errors?.internalDueDate && touched?.internalDueDate
                          }
                          helperText={
                            errors?.internalDueDate && touched?.internalDueDate
                              ? errors?.internalDueDate
                              : ""
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Opportunity Value"
                          variant="outlined"
                          type="number"
                          fullWidth
                          size="small"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AttachMoneyOutlinedIcon color="primary" />
                              </InputAdornment>
                            ),
                          }}
                          name="opportunityValue"
                          value={values.opportunityValue}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            errors?.opportunityValue &&
                            touched?.opportunityValue
                          }
                          helperText={
                            errors?.opportunityValue &&
                            touched?.opportunityValue
                              ? errors?.opportunityValue
                              : ""
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Bid Link"
                          variant="outlined"
                          type="text"
                          fullWidth
                          size="small"
                          name="bidLink"
                          value={values.bidLink}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors?.bidLink && touched?.bidLink}
                          helperText={
                            errors?.bidLink && touched?.bidLink
                              ? errors?.bidLink
                              : ""
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  {!state && (
                    <Box sx={{ marginTop: "20px" }}>
                      <Typography className={classes?.fieldsHeading}>
                        Document
                      </Typography>
                      <Divider sx={{ mb: 2 }} />

                      <FieldArray name="documents">
                        {({ push, remove }) => (
                          <Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography sx={{ fontWeight: "bold" }}>
                                Add Documents
                              </Typography>
                              <Tooltip title="Add Document">
                                <IconButton
                                  color="success"
                                  size="small"
                                  sx={{ padding: "6px" }}
                                  onClick={() =>
                                    push({
                                      type: "",
                                      file: "",
                                    })
                                  }
                                >
                                  <AddIcon fontSize="medium" />
                                </IconButton>
                              </Tooltip>
                            </Box>

                            <TableContainer
                              component={Box}
                              sx={{ marginTop: "10px", maxHeight: "300px" }}
                            >
                              <Table
                                sx={{ minWidth: "100%" }}
                                aria-label="simple table"
                              >
                                <TableHead className="table-head">
                                  <TableRow>
                                    <TableCell>Sr. </TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Document</TableCell>
                                    <TableCell>Delete</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {values.documents?.map((document, index) => {
                                    return (
                                      <TableRow
                                        key={index}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>
                                          <TextField
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            sx={{ minWidth: "200px" }}
                                            select
                                            name={`documents[${index}].type`}
                                            value={
                                              values.documents[index]?.type
                                            }
                                            onChange={(e) => {
                                              setFieldValue(
                                                `documents[${index}].type`,
                                                e.target.value
                                              );
                                            }}
                                            onBlur={handleBlur}
                                            error={
                                              errors?.documents &&
                                              touched?.documents
                                                ? errors?.documents[index]
                                                    ?.type &&
                                                  touched?.documents[index]
                                                    ?.type
                                                : false
                                            }
                                            helperText={
                                              errors?.documents &&
                                              touched?.documents
                                                ? errors?.documents[index]
                                                    ?.type &&
                                                  touched?.documents[index]
                                                    ?.type
                                                  ? errors?.documents[index]
                                                      ?.type
                                                  : ""
                                                : ""
                                            }
                                          >
                                            <MenuItem value="Addendum">
                                              Addendum
                                            </MenuItem>
                                            <MenuItem value="Bid Docs">
                                              Bid Docs
                                            </MenuItem>
                                            <MenuItem value="Billings">
                                              Billings
                                            </MenuItem>
                                            <MenuItem value="Tracking Sheet">
                                              Tracking Sheet
                                            </MenuItem>
                                            <MenuItem value="Change Order">
                                              Change Order
                                            </MenuItem>
                                            <MenuItem value="Change Order Document Template">
                                              Change Order Document Template
                                            </MenuItem>
                                            <MenuItem value="Contract Docs">
                                              Contract Docs
                                            </MenuItem>
                                            <MenuItem value="Correspondence">
                                              Correspondence
                                            </MenuItem>
                                            <MenuItem value="Drawings">
                                              Drawings
                                            </MenuItem>
                                            <MenuItem value="Invoices">
                                              Invoices
                                            </MenuItem>
                                            <MenuItem value="Post Mortem">
                                              Post Mortem
                                            </MenuItem>
                                            <MenuItem value="Proposal">
                                              Proposal
                                            </MenuItem>
                                            <MenuItem value="RFI">RFI</MenuItem>
                                            <MenuItem value="Schedules">
                                              Schedules
                                            </MenuItem>
                                            <MenuItem value="Specifications">
                                              Specifications
                                            </MenuItem>
                                            <MenuItem value="Warranty">
                                              Warranty
                                            </MenuItem>
                                            <MenuItem value="Work Order">
                                              Work Order
                                            </MenuItem>
                                          </TextField>
                                        </TableCell>
                                        <TableCell>
                                          <TextField
                                            variant="outlined"
                                            type="file"
                                            size="small"
                                            sx={{
                                              flex: 1,
                                            }}
                                            fullWidth
                                            name={`documents[${index}].file`}
                                            onChange={(e) => {
                                              setFieldValue(
                                                `documents[${index}].file`,
                                                {
                                                  src: URL.createObjectURL(
                                                    e.target.files[0]
                                                  ),
                                                  file: e.target.files[0],
                                                }
                                              );
                                            }}
                                            onBlur={handleBlur}
                                            error={
                                              errors?.documents &&
                                              touched?.documents
                                                ? errors?.documents[index]
                                                    ?.file &&
                                                  touched?.documents[index]
                                                    ?.file
                                                : false
                                            }
                                            helperText={
                                              errors?.documents &&
                                              touched?.documents
                                                ? errors?.documents[index]
                                                    ?.file &&
                                                  touched?.documents[index]
                                                    ?.file
                                                  ? errors?.documents[index]
                                                      ?.file
                                                  : ""
                                                : ""
                                            }
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <Tooltip title="Remove Document">
                                            <IconButton
                                              color="error"
                                              size="small"
                                              sx={{ padding: "6px" }}
                                              disabled={
                                                values?.types?.length == 1
                                              }
                                              onClick={() => {
                                                remove(index);
                                              }}
                                            >
                                              <DeleteOutlineIcon fontSize="inherit" />
                                            </IconButton>
                                          </Tooltip>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        )}
                      </FieldArray>
                    </Box>
                  )}

                  <Box
                    sx={{
                      marginTop: "30px",
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "10px",
                    }}
                  >
                    <ThreeDots
                      height="40"
                      width="40"
                      radius="9"
                      color="#f79c35"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={isSubmitting}
                    />

                    <Button
                      color="error"
                      variant="outlined"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      {state ? "Update" : "Save"}
                    </Button>
                  </Box>
                </Form>

                <ContactsFormDialog
                  open={showContactDialog}
                  onClose={(success) => {
                    if (success) {
                      dispatch(fetchOpportunityPopulateData());
                    }
                    setContactDialogFor("");
                    setShowContactDialog(false);
                  }}
                  screen="opportunity"
                  contactDialogFor={contactDialogFor}
                  setFieldValue={setFieldValue}
                />

                <CompaniesFormDialog
                  open={showCompanyDialog}
                  onClose={(success) => {
                    if (success) {
                      dispatch(fetchOpportunityPopulateData());
                    }
                    setShowCompanyDialog(false);
                  }}
                  screen="opportunity"
                  setFieldValue={setFieldValue}
                />

                <AddUpdateDialog
                  open={showAddDialog}
                  onClose={() => {
                    setHeading("");
                    setShowAddDialog(false);
                  }}
                  heading={heading}
                  onSuccess={(id) => {
                    if (heading === "Source") {
                      setFieldValue("sourceId", id);
                    } else if (heading === "Type") {
                      setFieldValue("typeId", id);
                    } else if (heading === "Tag") {
                      setFieldValue("tagId", [...values.tagId, id]);
                    }

                    dispatch(fetchOpportunityPopulateData());
                  }}
                />
              </>
            );
          }}
        </Formik>

        {status === "loading" ? <Loader /> : null}
      </Box>
    </>
  );
}
export default OpportunityForm;

const useStyles = makeStyles(() => ({
  formContainer: {
    padding: "1.5rem",
  },
  fieldsHeading: {
    fontSize: "1.125rem !important",
    color: "rgb(38 50 56 /1) !important",
    lineHeight: "1.625 !important",
    letterSpacing: "0 !important",
    fontWeight: "600 !important",
    marginBottom: "10px !important",
  },
}));
