import React, { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { makeStyles } from "@mui/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useLocation } from "react-router-dom";
import { BreadcrumbsContext } from "../../../context/BcrumbsContextProvider";
import PropTypes from "prop-types";
import theme from "../../../theme/theme";
import SearchIcon from "@mui/icons-material/Search";
import {
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSearchData,
  resetSearchData,
} from "../../../redux/slice/SearchSlice/SearchSlice";
import { ThreeDots } from "react-loader-spinner";

function Navbar({ toggleDrawer }) {
  const [searchFilter, setSearchFilter] = useState("");
  const [debounceTimer, setDebounceTimer] = useState(null);

  const classes = useStyles();
  const location = useLocation();
  const { breadcrumbsItems, setBreadcrumbsItems } =
    useContext(BreadcrumbsContext);

  const dispatch = useDispatch();
  const { SearchData, status } = useSelector((state) => state?.Search);

  useEffect(() => {
    // Clean up the timer on unmount
    return () => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
    };
  }, [debounceTimer]);

  const getPageName = () => {
    let pathName =
      location.pathname.match(/(?<=\/)\w+$|\w+-\w+$|\w+_\w+$/)?.[0] || "home";
    return pathName?.split(/[-,_,\s]/g)?.join(" ");
  };

  const handleBreadcrumbs = (name, idx) => {
    if (name === "Dashboard") {
      setBreadcrumbsItems([
        {
          id: breadcrumbsItems.length,
          url: "/",
          name: "Dashboard",
        },
        {
          id: breadcrumbsItems.length,
          url: "/",
          name: "Home",
        },
      ]);
    } else {
      let newBreadCrumbs = JSON.parse(JSON.stringify(breadcrumbsItems));
      newBreadCrumbs = newBreadCrumbs?.filter((item, index) => index <= idx);
      setBreadcrumbsItems(newBreadCrumbs);
    }
  };

  const handleGetApiCall = (Searchkey) => {
    if (Searchkey) {
      dispatch(
        fetchSearchData({
          Searchkey,
        })
      );
    } else {
      dispatch(resetSearchData());
    }
  };

  const handleFilterChange = (e) => {
    const { value } = e.target;

    setSearchFilter(value);

    // Clear the previous timer
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    // Set a new timer
    setDebounceTimer(
      setTimeout(() => {
        handleGetApiCall(value);
      }, 500) // 500ms debounce time
    );
  };

  return (
    <header className={classes.headerContainer}>
      <nav className={classes.navbarContainer}>
        <Box className={classes.navbarLinksContainer}>
          <Box className={classes.navBtnContainer}>
            <IconButton
              className={classes.navIconsBtn}
              onClick={() => toggleDrawer(true)}
              sx={{
                display: { lg: "none" },
              }}
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Box sx={{ width: "500px", position: "relative" }}>
            <TextField
              variant="outlined"
              type="text"
              fullWidth
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ fontSize: "20px" }} />
                  </InputAdornment>
                ),
              }}
              name="search"
              value={searchFilter}
              onChange={handleFilterChange}
            />

            <Box
              sx={{
                position: "absolute",
                zIndex: 999,
                width: "100%",
                maxHeight: "300px",
                overflow: "auto",
                backgroundColor: "white",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                borderRadius: "8px",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <ThreeDots
                  height="40"
                  width="40"
                  radius="9"
                  color="#f79c35"
                  ariaLabel="three-dots-loading"
                  visible={status == "loading"}
                />
              </Box>

              {status !== "loading" && SearchData?.length ? (
                <List>
                  {SearchData?.map((data, idx) => (
                    <ListItem key={idx}>
                      <ListItemText
                        primary={data?.title}
                        secondary={data?.status}
                      />
                    </ListItem>
                  ))}
                </List>
              ) : null}
            </Box>
          </Box>
        </Box>

        <Box>
          <Breadcrumbs aria-label="breadcrumb">
            {breadcrumbsItems.map((item, index) => {
              if (index !== breadcrumbsItems.length - 1) {
                return (
                  <Link
                    key={item.id}
                    className={classes.breadcrumbsLink}
                    to={item.url}
                    onClick={() => handleBreadcrumbs(item?.name, index)}
                  >
                    {item.name}
                  </Link>
                );
              } else {
                return (
                  <Typography
                    key={item.id}
                    sx={{
                      fontSize: ".875rem",
                      lineHeight: "1.5",
                      color: "rgb(38 50 56 /1)",
                    }}
                  >
                    {item.name}
                  </Typography>
                );
              }
            })}
          </Breadcrumbs>

          <Typography
            variant="h6"
            sx={{
              fontSize: "1rem",
              lineHeight: "1.625",
              color: "rgb(38 50 56 /1)",
              letterSpacing: 0,
              fontWeight: 600,
              textTransform: "capitalize",
            }}
          >
            {getPageName()}
          </Typography>
        </Box>
      </nav>
    </header>
  );
}

export default Navbar;
Navbar.propTypes = {
  toggleDrawer: PropTypes.func,
};

const useStyles = makeStyles(() => ({
  navbarContainer: {
    padding: "4px 0",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row-reverse",
      justifyContent: "space-between",
    },
  },

  navbarLinksContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "15px",
  },

  navBtnContainer: {
    display: "flex",
    gap: "10px",
  },

  navbarInfoContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },

  navIconsBtn: {
    color: "rgb(96 125 139 /1) !important",
    fontWeight: "500 !important",
    fontSize: ".75rem !important",
    lineHeight: "1rem !important",
    borderRadius: "0.5rem !important",
    maxWidth: "40px",
    height: "2.5rem",
  },

  breadcrumbsLink: {
    fontSize: ".875rem",
    lineHeight: "1.5",
    color: "rgb(38 50 56 /.5)",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));
