import { React, useState, useContext, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import { ThreeDots } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import MuiPhoneNumber from "material-ui-phone-number";
import {
  Autocomplete,
  Avatar,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { City, State } from "country-state-city";
import * as Yup from "yup";
import { SnackBarContext } from "../../context/SnackBarContext";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanyData } from "../../redux/slice/CompanySlice/CompanySlice";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  CreateCompanyApi,
  UpdateCompanyApi,
} from "../../Apis/Companies/CompaniesApis";
import PropTypes from "prop-types";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, RichUtils, getDefaultKeyBinding } from "draft-js";
import { convertToHTML } from "draft-convert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { fetchNotes } from "../../redux/slice/ContactsSlice/ContactSlice";
import AddNoteDialog from "../AddNoteDialog/AddNoteDialog";
import Loader from "../Loader/Loader";
import AppConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import { DeleteNotesApi } from "../../Apis/Notes/NotesApi";
import moment from "moment";
function CompaniesForm({ parent, onClose, screen, setFieldValue }) {
  const classes = useStyles();
  const companyData = useLocation();
  const { Notes, status } = useSelector((state) => state?.Contacts);

  const [loading, setLoading] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [states, setStates] = useState([]);
  const [state, setState] = useState(null);
  const [cities, setCities] = useState([]);
  const [image, setImage] = useState(null);
  const [update] = useState(
    companyData?.state?.companyId && companyData?.state?.name ? true : false
  );
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [note, setNote] = useState("");
  const [showAddNotesDialog, setShowAddNotesDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const inputRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    update &&
      dispatch(
        fetchNotes({ type: "Company", id: companyData?.state?.companyId })
      );
  }, [dispatch, companyData.state]);
  useEffect(() => {
    if (update) {
      console.log(companyData?.state);
      formik.setFieldValue("name", companyData.state?.name);
      formik.setFieldValue("email", companyData.state?.email);
      formik.setFieldValue("phone", companyData.state?.phone);
      formik.setFieldValue("state", companyData.state?.state);
      formik.setFieldValue("city", companyData.state?.city);
      formik.setFieldValue("address", companyData.state?.address);
      formik.setFieldValue("zipCode", companyData.state?.zipCode);
      formik.setFieldValue("type", companyData.state?.type);
      setImage({
        url: companyData?.state?.image,
      });
    }
  }, [companyData]);
  const validationSchema = Yup.object({
    phone: Yup.string(),
    email: Yup.string(),
    address: Yup.string(),
    city: cities?.length
      ? Yup.string().required("Field required!")
      : Yup.string(),
    state: Yup.string().required("Field required!"),
    name: Yup.string().required("Field required!"),
    type: Yup.string().required("Field required!"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      address: "",
      zipCode: "",
      email: "",
      state: "",
      city: "",
      phone: "",
      type: parent === "dialog" && screen === "opportunity" ? "Client" : "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const formData = new FormData();
      companyData?.state?.companyId &&
        formData.append("companyId", companyData?.state?.companyId);
      formData.append("zipCode", values?.zipCode?.toString());
      formData.append("name", values?.name);
      formData.append("address", values?.address);
      formData.append("email", values?.email);
      formData.append("state", values?.state);
      formData.append("city", values?.city);
      formData.append("phone", values?.phone);
      formData.append("type", values?.type);
      image?.file
        ? formData.append("image", image?.file)
        : image?.url
        ? null
        : formData.append("image", "");
      if (note) {
        formData.append("note", note);
      }

      if (companyData.state?.companyId && companyData.state?.name) {
        const response = await UpdateCompanyApi(
          companyData?.state?.companyId,
          formData
        );
        setLoading(false);
        response?.snackBarData?.message &&
          setsnackBarData(response?.snackBarData);
        if (response?.success) {
          dispatch(fetchCompanyData());
          resetForm();
          navigate(-1);
        }
      } else {
        const response = await CreateCompanyApi(formData);
        setLoading(false);

        response?.snackBarData?.message &&
          setsnackBarData(response?.snackBarData);
        if (response?.success) {
          // dispatch(fetchAllReceivings());
          resetForm();
          if (parent == "dialog") {
            onClose(true);
            setFieldValue("companyId", response?.data?.companyId);
          } else {
            navigate(-1);
          }
        }
      }
    },
  });
  const handlePhoneChange = (value) => {
    formik.setFieldValue("phone", value);
  };

  useEffect(() => {
    let value = State.getStatesOfCountry("US");
    value = value?.map((data) => {
      let obj = {
        label: data?.name,
        value: data?.isoCode,
      };
      return obj;
    });
    setStates(value);
  }, []);
  useEffect(() => {
    if (state) {
      let value = City.getCitiesOfState("US", state);
      value = value?.map((data) => {
        return data?.name;
      });
      if (state === "FL") {
        value.push("Parrish", "Myakka");
      }
      setCities(value);
    } else {
      setCities([]);
    }
  }, [state]);
  const handleLogo = (e) => {
    setImage({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  };
  const styleToHTML = (style) => {
    if (style.startsWith("color-")) {
      const color = style.replace("color-", "");
      return <span style={{ color: color }} />;
    }
  };

  useEffect(() => {
    const html = convertToHTML({
      styleToHTML,
    })(editorState.getCurrentContent());

    if (html !== "<p></p>") {
      setNote(html);
    }
  }, [editorState]);
  const onChange = async (value) => {
    setEditorState(value);
  };

  const handleKeyCommand = (command, editorState) => {
    // Intercept Enter key press
    if (command === "split-block") {
      // Create a new EditorState with a soft line break instead of a new paragraph
      const newState = RichUtils.insertSoftNewline(editorState);
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const mapKeyToEditorCommand = (e) => {
    // Handle Enter key press with shift
    if (e.keyCode === 13 /* 'Enter' key */ && e.shiftKey) {
      return "split-block";
    }
    return getDefaultKeyBinding(e);
  };
  const deleteNote = async () => {
    setLoading(true);
    setShowConfirmation(false);

    const response = await DeleteNotesApi(selectedRow?.notesId);

    response?.snackBarData?.message && setsnackBarData(response?.snackBarData);
    if (response?.success) {
      dispatch(
        fetchNotes({
          type: "Company",
          id: companyData?.state?.companyId,
        })
      );
      setSelectedRow(null);
    }
    setLoading(false);
  };
  // const handleUploadClick = () => {
  //   if (inputRef.current) {
  //     inputRef.current.click();
  //   }
  // };
  return (
    <Box
      sx={{
        backgroundColor: parent == "dialog" ? "transparent" : "#fff",
        borderRadius: parent == "dialog" ? "0px" : "12px",
        boxShadow: parent == "dialog" ? "none" : "0 3px 10px rgb(0 0 0 / 0.2)",
        width: "100%",
        height: parent == "dialog" ? "auto" : "calc(100vh - 100px)",
        overflowY: parent == "dialog" ? "hidden" : "scroll",
      }}
    >
      <form
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
        className={classes.formContainer}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {parent == "screen" && (
              <>
                <Typography className={classes?.fieldsHeading}>
                  Company Information
                </Typography>
                <Divider sx={{ mb: 2 }} />
              </>
            )}

            <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // backgroundColor: "#f0f9fb",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      width: "140px",
                      height: "140px",
                      // height: "100%",
                      position: "relative",
                    }}
                  >
                    {image?.url ? (
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          maxWidth: "100px",
                          // borderRadius: "5px",
                          // border: "1px solid rgb(214, 214, 214)",
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer !important",
                          justifyContent: "center",
                          position: "relative",
                          borderRadius: "50%",
                          // padding: "10px",
                        }}
                      >
                        <img
                          src={image?.url}
                          alt="Image"
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                          }}
                        />
                        <CancelIcon
                          color="error"
                          onClick={() => {
                            setImage(null);
                          }}
                          sx={{
                            position: "absolute",
                            top: "-2px",
                            right: "-2px",
                            fontSize: "16px",
                            zIndex: 99,
                          }}
                        />
                      </Box>
                    ) : (
                      <Avatar
                        sx={{
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                        }}
                        src={"./"}
                      >
                        <Typography>Upload Logo</Typography>
                      </Avatar>
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      name="product_images"
                      style={{
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                        top: "0px",
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      onChange={handleLogo}
                      ref={inputRef}
                    />
                  </Box>

                  {/* <Button
                    variant="outlined"
                    color="info"
                    onClick={handleUploadClick}
                    sx={{ mt: 2, textTransform: "capitalize" }}
                  >
                    Upload Image
                  </Button> */}
                </Box>
              </Grid>
              <Grid item xs={12} md={10}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="name"
                      type="text"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      label="Name"
                      onBlur={formik.handleBlur}
                      error={formik.errors?.name && formik.touched?.name}
                      helperText={
                        formik.errors?.name && formik.touched?.name
                          ? formik.errors?.name
                          : ""
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      select
                      name="type"
                      variant="outlined"
                      fullWidth
                      size="small"
                      disabled={parent === "dialog" && screen === "opportunity"}
                      value={formik.values.type}
                      onChange={formik.handleChange}
                      label="Type"
                      onBlur={formik.handleBlur}
                      error={formik.touched.type && Boolean(formik.errors.type)}
                      helperText={formik.touched.type && formik.errors.type}
                    >
                      <MenuItem value={"Client"}>Client</MenuItem>
                      <MenuItem value={"Vendor"}>Vendor</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MuiPhoneNumber
                      defaultCountry={"us"}
                      disableAreaCodes={true}
                      regions={["america"]}
                      onlyCountries={["us"]}
                      value={formik.values?.phone}
                      onChange={handlePhoneChange}
                      variant="outlined"
                      label="Phone"
                      name="number"
                      size="small"
                      fullWidth
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                      helperText={formik.touched.phone && formik.errors.phone}
                      // error={phoneError}
                      // helperText={phoneError && "Number must contain 11 digits"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      type="email"
                      name="email"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      label="Email"
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>

                  <Grid item md={12}>
                    <Typography className={classes?.fieldsHeading}>
                      Company Address
                    </Typography>
                    <Divider sx={{ mb: 2 }} />

                    <TextField
                      type="text"
                      name="address"
                      variant="outlined"
                      fullWidth
                      size="small"
                      multiline
                      rows={3}
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      label="Address"
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.address && Boolean(formik.errors.address)
                      }
                      helperText={
                        formik.touched.address && formik.errors.address
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched.state && Boolean(formik.errors.state)
                      }
                    >
                      <Autocomplete
                        id="combo-box-demo"
                        size="small"
                        style={{ width: "100%" }}
                        options={states}
                        value={formik.values?.state}
                        // getOptionLabel={(option) => option.label}
                        onChange={(e, v) => {
                          setState(v?.value);
                          formik.setFieldValue("state", v?.label);
                          formik.setFieldValue("city", "");
                        }}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField {...params} label="State" />
                        )}
                      />
                      <FormHelperText>
                        {formik.touched.state && formik.errors.state}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl
                      fullWidth
                      error={formik.touched.city && Boolean(formik.errors.city)}
                    >
                      <Autocomplete
                        disablePortal
                        size="small"
                        style={{ width: "100%" }}
                        value={formik.values?.city}
                        id="combo-box-demo"
                        options={cities}
                        onChange={(e, v) => {
                          formik.setFieldValue("city", v);
                        }}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField fullWidth {...params} label="City" />
                        )}
                      />
                      <FormHelperText>
                        {formik.touched.city && formik.errors.city}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      type="number"
                      name="zipCode"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={formik.values.zipCode}
                      onChange={formik.handleChange}
                      label="Zip Code"
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.zipCode && Boolean(formik.errors.zipCode)
                      }
                      helperText={
                        formik.touched.zipCode && formik.errors.zipCode
                      }
                    />
                  </Grid>
                  {!update ? (
                    <Grid item xs={12}>
                      <Typography className={classes?.fieldsHeading}>
                        Notes
                      </Typography>
                      <Divider sx={{ mb: 2 }} />
                      <FormControl fullWidth>
                        <Box
                          sx={{
                            maxHeight: "500px",
                            overflow: "auto",
                            border:
                              Boolean(formik.errors.body) &&
                              Boolean(formik.touched.body)
                                ? "1px solid #d32f2f"
                                : "1px solid #bdbdbd",
                            padding: "8px",
                          }}
                        >
                          <Editor
                            placeholder="Start Typing your note here"
                            editorState={editorState}
                            handleKeyCommand={handleKeyCommand}
                            keyBindingFn={mapKeyToEditorCommand}
                            onEditorStateChange={(value) => {
                              onChange(value);
                            }}
                          />
                        </Box>
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {Boolean(formik.errors.body) &&
                          Boolean(formik.touched.body)
                            ? "Field Required!"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box
          sx={{
            marginTop: "30px",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <ThreeDots
            height="40"
            width="40"
            radius="9"
            color="#f79c35"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={loading}
          />

          {parent == "screen" && (
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </Button>
          )}

          <Button
            type="submit"
            variant="contained"
            disabled={loading}
            color="primary"
          >
            Save
          </Button>
        </Box>

        {update && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}></Grid>
            <Grid item xs={12} md={10}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes?.fieldsHeading}>
                    Note
                  </Typography>
                  <Divider sx={{ mb: 2 }} />

                  <TableContainer
                    component={Box}
                    sx={{ marginTop: "5px", maxHeight: "300px" }}
                  >
                    <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                      <TableHead className="table-head">
                        <TableRow>
                          <TableCell width={"5%"}>Sr.</TableCell>
                          <TableCell width={"50%"}>Notes</TableCell>
                          <TableCell>Created By</TableCell>
                          <TableCell>Created At</TableCell>

                          <TableCell>
                            {" "}
                            <Button
                              variant="contained"
                              sx={{ float: "right" }}
                              size="small"
                              onClick={() => {
                                setShowAddNotesDialog(true);
                              }}
                            >
                              Add New
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Notes?.data?.map((item, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell>{index + 1}</TableCell>

                            <TableCell
                              dangerouslySetInnerHTML={{ __html: item?.note }}
                            ></TableCell>
                            <TableCell>
                              {item?.createdByUser?.name || "N/A"}
                            </TableCell>
                            <TableCell>
                              {moment(
                                item?.createdAt,
                                "YYYY-MM-DDTHH:mm:ss a"
                              )?.format("MM/DD/YYYY") || "N/A"}
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{
                                  display: "flex",
                                  float: "right",
                                  mr: 2,
                                }}
                              >
                                <Tooltip title="Edit">
                                  <IconButton
                                    sx={{ padding: "5px" }}
                                    color="info"
                                    onClick={() => {
                                      setSelectedRow(item);
                                      setShowAddNotesDialog(true);
                                    }}
                                  >
                                    <EditIcon sx={{ fontSize: "1rem" }} />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                  <IconButton
                                    sx={{ padding: "5px" }}
                                    color="error"
                                    onClick={() => {
                                      setSelectedRow(item);
                                      setShowConfirmation(true);
                                    }}
                                  >
                                    <DeleteOutlineIcon
                                      sx={{ fontSize: "1rem" }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </form>
      {showAddNotesDialog && (
        <AddNoteDialog
          open={showAddNotesDialog}
          onClose={(success) => {
            if (success)
              dispatch(
                fetchNotes({
                  type: "Company",
                  id: companyData?.state?.companyId,
                })
              );
            setShowAddNotesDialog(false);
            setSelectedRow(null);
          }}
          setNote={setNote}
          referenceId={companyData?.state?.companyId}
          referenceType={"Company"}
          updateData={selectedRow}
        />
      )}
      {status === "loading" && <Loader />}
      {showConfirmation && (
        <AppConfirmationDialog
          open={showConfirmation}
          onDeny={() => {
            setSelectedRow(null);
            setShowConfirmation(false);
          }}
          onConfirm={() => {
            deleteNote();
          }}
          iconContainerColor="#fbeaea"
          icon={
            <DeleteOutlineIcon
              fontSize="small"
              sx={{ color: (theme) => theme?.palette?.error?.main }}
            />
          }
          dialogTitle="Delete Note"
          title={`Are you sure, you want to delete selected note?`}
          successText="Yes"
          successBtnColor="error"
          cancelText="No"
        />
      )}
    </Box>
  );
}

export default CompaniesForm;
CompaniesForm.propTypes = {
  parent: PropTypes.string,
  onClose: PropTypes.func,
  screen: PropTypes.string,
  setFieldValue: PropTypes.func,
};

const useStyles = makeStyles(() => ({
  formContainer: {
    padding: "1.5rem",
  },
  fieldsHeading: {
    fontSize: "1.125rem !important",
    color: "rgb(38 50 56 /1) !important",
    lineHeight: "1.625 !important",
    letterSpacing: "0 !important",
    fontWeight: "600 !important",
    marginBottom: "10px !important",
  },
}));
